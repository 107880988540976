<template>
    <div class="preview_area show_sidebar" v-if="modelValue" :style="`--var-color: ${selectedPlaybook.step_complete_button && selectedPlaybook.step_complete_button.bg_color != null ? selectedPlaybook.step_complete_button.bg_color : '#2f7fed'}; --var-progress-color: ${selectedPlaybook.progressbar_color ? selectedPlaybook.progressbar_color : '#2f7eed'}`">
        <div class="global_setting">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <draggable v-model="playbookSteps" tag="ul" class="tabs_btn" item-key="id" @change="handleStepsSort" handle=".handle-cat-drag">
                <template #item="{element}">
                    <li @click="stepSwitch(element)" :class="{ active: selectedStep.id === element.id }"><i class="fas fa-arrows-alt handle-cat-drag"></i>{{ element.title }}</li>
                </template>
                <template #footer>
                    <li class="close_btn" @click="toggleSectionbar"><img src="@/assets/images/bar.svg"></li>
                    <li class="add_btn"><button type="button" @click="createStep = true"><i class="fas fa-plus"></i>Add Step</button></li>
                </template>
            </draggable>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <div class="step_loader_area" v-if="stepLoader"><quote-loader/></div>
                <Form v-else @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="playbook-step" class="playbook-step">
                    <div class="content_area">
                        <!-- <swiper :slidesPerView="'auto'" :spaceBetween="30" :navigation='true' class="tab_slider topTabs">
                            <swiper-slide><span @click="tab = 'setting'" :class="{ active: tab === 'setting' }">Step Settings</span></swiper-slide>
                            <swiper-slide><span @click="tab = 'image'" :class="{ active: tab === 'image' }">Image Settings</span></swiper-slide>
                            <swiper-slide><span @click="tab = 'main'" :class="{ active: tab === 'main' }">Main Content</span></swiper-slide>
                            <swiper-slide><span @click="tab = 'drip'" :class="{ active: tab === 'drip' }">Drip Settings</span></swiper-slide>
                            <swiper-slide><span @click="tab = 'downloads'" :class="{ active: tab === 'downloads' }">Downloads</span></swiper-slide>
                        </swiper>
                        <div class="content_wpr" v-show="tab == 'setting'">
                            <div class="section_content w-100" :class="!preview ? 'w-100' : ''">
                                <div class="section_header">
                                    <h2>Live Step URL</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0 mt-2">
                                        <div class="group_item">
                                            <div class="field_wpr has_suffix">
                                                <Field autocomplete="off" type="text" name="step_url" v-model="form.step_url" readonly />
                                                <span class="suffix pointer" @click="copyStepUrl">Copy</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="color_container mt-3">
                                    <label for="draft" class="switch_option capsule_btn">
                                        <h5 class="large">Visible In Draft Mode With Link</h5>
                                        <input type="checkbox" id="draft" :true-value="1" :false-value="0" v-model="form.url_active" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="section_header mt-5" @click="goto('ttlsection')" ref="ttlsetting">
                                    <h2>Settings</h2>
                                </div>
                                <div class="color_container mt-2" @click="goto('ttlsection')" ref="ttlsetting">
                                    <div class="setting_wpr">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.title }">
                                                    <Field autocomplete="off" type="text" name="title" placeholder="ex: Step 1" v-model="form.title"  rules="required" />
                                                </div>
                                                <ErrorMessage name="title" class="text-danger" />
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Description</label>
                                                <div class="field_wpr" :class="{ 'has-error': errors.thumb_desc }">
                                                    <Field autocomplete="off" type="textarea" name="thumb_desc" v-model="form.thumb_desc" label="description" >
                                                        <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.thumb_desc"></textarea>
                                                    </Field>
                                                </div>
                                                <ErrorMessage name="thumb_desc" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                    <label for="smart" class="switch_option capsule_btn">
                                        <h5 class="large">Save as smart step</h5>
                                        <input type="checkbox" id="smart" :true-value="1" :false-value="0" v-model="form.is_smart_step" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="setting_wpr" v-if="form.is_smart_step">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Smart Step Title</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.smart_step_title }">
                                                    <Field autocomplete="off" type="text" name="smart_step_title" placeholder="ex: Smart Step" v-model="form.smart_step_title" />
                                                </div>
                                                <ErrorMessage name="smart_step_title" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-show="tab == 'image'">
                            <div class="section_content w-100" :class="!preview ? 'w-100' : ''" @click="goto('cvrsection')" ref="cvrsetting">
                                <div class="section_header">
                                    <h2>Images</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <ul class="tab_sec mb-5">
                                        <li @click="imageTab = 'thumb'" :class="{ active: imageTab === 'thumb' }">Step Thumbnail</li>
                                        <li @click="imageTab = 'banner'" :class="{ active: imageTab === 'banner' }">Inside Banner</li>
                                    </ul>
                                    <div v-if="imageTab === 'thumb'">
                                        <label for="step_thumb" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="step_thumb" :true-value="1" :false-value="0" v-model="form.display_thumb" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-if="form.display_thumb">
                                            <image-library v-model="form.thumbnail" image-type="playbook-step-thumb" upload-text="Thumbnail" />
                                        </div>
                                    </div>
                                    <div v-if="imageTab === 'banner'">
                                        <label for="inside_thumb" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="inside_thumb" :true-value="1" :false-value="0" v-model="form.display_banner" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-if="form.display_banner">
                                            <image-library v-model="form.banner" image-type="playbook-step-banner" upload-text="Banner" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-show="tab == 'main'">
                            <div class="section_content w-100" :class="!preview ? 'w-100' : ''" ref="mainsetting">
                                <div @click="goto('mainsection')">
                                    <div class="section_header">
                                        <h2>Show Main Video or Image</h2>
                                        <label for="main-video" class="switch_option capsule_btn p-0 mt-4 mb-3 border-0">
                                            <input type="checkbox" id="main-video" v-model="form.show_main_video" :true-value="1" :false-value="0" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <div class="color_container">
                                        <content-thumbnail v-model="stepMainThumbnail" :errors="errors" :tab="tab"></content-thumbnail>
                                    </div>
                                    <div v-show="form.has_additional_video" class="mt-4">
                                        <div class="section_header">
                                            <h2>Show Additional Video or Image</h2>
                                            <label for="additional-video" class="switch_option capsule_btn p-0 mt-4 mb-3 border-0">
                                                <input type="checkbox" id="additional-video" v-model="form.show_additional_video" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="color_container">
                                            <content-thumbnail v-model="stepAdditionalThumbnail" :errors="errors" :reload="stepAdditionalThumbnailReload" :tab="tab"></content-thumbnail>
                                        </div>
                                    </div>
                                    <ul class="tabs_action mt-2">
                                        <li>
                                            <button type="button" v-if="form.has_additional_video == 0" class="add_btn pointer m-0" @click="form.has_additional_video = 1"><i class="fas fa-plus"></i>Add Video</button>
                                            <button type="button" v-else class="add_btn pointer m-0" @click="form.has_additional_video = 0"><i class="fas fa-plus"></i>Remove Video</button>
                                        </li>
                                    </ul>
                                    <div class="section_header mt-5">
                                        <h2>Step Content</h2>
                                    </div>
                                    <div class="color_container mt-2">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <redactor v-model="form.description" name="description" :default-value="selectedStep.description" ref="step-editor"  :email-editor="false" :reset-watcher="stepContentResetWatcher" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section_header mt-5">
                                    <h2>Additional Tabs</h2>
                                    <label for="additional_tab" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="additional_tab" :true-value="1" :false-value="0" v-model="form.is_tabs" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container mt-2">
                                    <ul class="tabs_action">
                                        <li><button type="button" class="add_btn pointer" @click="addTab = !addTab"><i class="fas fa-plus"></i>Add Tab</button></li>
                                    </ul>
                                    <div v-if="addTab">
                                        <Form @submit="handleAddTab" v-slot="{ errors }">
                                            <div class="setting_wpr mb-3">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Title</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.tab_title }">
                                                            <Field autocomplete="off" type="text" name="tab_title" placeholder="ex: New Tab" v-model="tabForm.title" />
                                                        </div>
                                                        <ErrorMessage name="tab_title" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="action_wpr">
                                                    <button type="button" class="btn cancel_btn" @click="addTab = false">Cancel</button>
                                                    <button class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookStepTabSaveLoader"></i> {{ playbookStepTabSaveLoader ? 'Saving' : 'Save' }}</button>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                    <draggable v-model="stepTabs" tag="ul" item-key="id" class="tab_sec scr_tab mb-3 mt-3" @change="handleTabSort">
                                        <template #item="{element}">
                                            <li class="move" @click="resetTabForm(element); activeTab = element;" :class="{ active: selectedTab.id === element.id }">
                                                {{ element.title }} <span class="delete pointer" @click="handleTabDelete(element.id)"><i class="fas fa-times"></i></span>
                                            </li>
                                        </template>
                                    </draggable>
                                    <div v-show="selectedTab.id">
                                        <div class="color_container mt-2">
                                            <div class="group_item">
                                                <label for="display-tab-toggle" class="switch_option capsule_btn m-0 border-0">
                                                    <h5 class="large">Display Tab</h5>
                                                    <input type="checkbox" id="display-tab-toggle" :true-value="1" :false-value="0" @change="handleUpdateTab(selectedTab)" v-model="selectedTab.display_tab" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </div>
                                        <div v-show="selectedTab.display_tab">
                                            <div class="setting_wpr mb-3">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Title</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.tab_title }">
                                                            <Field autocomplete="off" type="text" name="tab_title" placeholder="ex: New Tab" v-model="selectedTab.title" @focusout="handleUpdateTab(selectedTab)" />
                                                        </div>
                                                        <ErrorMessage name="tab_title" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="color_container mt-2">
                                                <div class="group_item">
                                                    <label class="input_label">Tab Background Color</label>
                                                    <Field autocomplete="off" name="bg_color" v-model="selectedTab.bg_color" type="text" label="element color">
                                                        <color-picker v-model="selectedTab.bg_color" :classes="{ 'has-error': errors.bg_color }" />
                                                    </Field>
                                                    <ErrorMessage name="bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="color_container mt-2">
                                                <div class="group_item">
                                                    <label class="input_label">Tab Text Color</label>
                                                    <Field autocomplete="off" name="text_color" v-model="selectedTab.text_color" type="text" label="element color">
                                                        <color-picker v-model="selectedTab.text_color" :classes="{ 'has-error': errors.text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="text_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="color_container mt-2" v-show="stepTabs && stepTabs.length && (activeTab.id == stepTabs[0].id)">
                                                <label for="apply-to-all" class="switch_option capsule_btn m-0 border-0">
                                                    <h5 class="large">Apply to all tabs</h5>
                                                    <input type="checkbox" id="apply-to-all" @click="confimApplyToAllTabs" :true-value="1" :false-value="0" v-model="form.apply_to_all" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                            <div class="mt-5">
                                                <content-thumbnail v-model="selectedTab.thumbnail" :errors="errors" :is-dynamic="true" :tab="tab" title="Video or image title Text"></content-thumbnail>
                                            </div>
                                            <redactor v-model="selectedTab.content" name="tab_content" :default-value="activeTab.content" ref="tab-editor" :reset-watcher="tabContentResetWatcher" :email-editor="false" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="tab == 'drip'">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Drip</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <ul class="tab_sec mb-4">
                                        <li @click="dripTab = 'step'" :class="{ active: dripTab === 'step' }">Drip</li>
                                        <li @click="dripTab = 'tab'" :class="{ active: dripTab === 'tab' }">Tabs Drip</li>
                                    </ul>
                                    <div v-show="dripTab === 'step'">
                                        <label for="drip_schedule" class="switch_option capsule_btn m-0 border-0">
                                            <h5 class="large">Schedule</h5>
                                            <input type="checkbox" id="drip_schedule" :true-value="1" :false-value="0" v-model="form.drip_schedule" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <drip-component v-show="form.drip_schedule" :errors="errors" v-model="stepDripForm" />
                                        <label for="drip_notification" class="switch_option capsule_btn m-0 border-0">
                                            <h5 class="large">Notification</h5>
                                            <input type="checkbox" id="drip_notification" :true-value="1" :false-value="0" v-model="form.drip_notification" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-show="form.drip_notification">
                                            <div class="section_wpr mb-2">
                                                <div class="edit_section">
                                                    <sending-method v-model="form.drip_notification_method" />
                                                </div>
                                                <div v-if="form.drip_notification_method == 1 || form.drip_notification_method == 3">
                                                    <email-component v-if="loaded" v-model="stepDripEmail" :errors="errors" :handle-default-email="handleStepDripDefaultEmail" preview-module="playbook" ref="step-drip-email-component" />
                                                </div>
                                                <div v-if="form.drip_notification_method == 2 || form.drip_notification_method == 3" class="sms-component">
                                                    <sms-component v-if="loaded" v-model="form.drip_notification_sms" media-field-name="drip_notification_sms_media" :default-sms-handler="true" preview-module="playbook" :handle-default-sms="handleStepDripDefaultSms" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-show="dripTab === 'tab'">
                                        <label for="tabdrip_schedule" class="switch_option capsule_btn m-0 border-0">
                                            <h5 class="large">Schedule</h5>
                                            <input type="checkbox" id="tabdrip_schedule" :true-value="1" :false-value="0" v-model="form.has_tabs_drip" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-if="form.has_tabs_drip && stepTabs.length">
                                            <ul class="tab_sec scr_tab">
                                                <template v-for="(stepTab, t) of stepTabs" :key="t">
                                                    <li class="px-0" @click="selectedTabDrip = stepTab" :class="{ active: selectedTabDrip.id == stepTab.id }">{{ stepTab.title }}</li>
                                                </template>
                                            </ul>
                                            <div class="tab_content">
                                                <span v-show="form.has_tabs_drip">
                                                    <span v-for="(dripForm, df) of tabDripForm" :key="df">
                                                        <drip-component :errors="errors" v-model="tabDripForm[df]" v-show="selectedTabDrip.id == dripForm.id" />
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                        <p v-else-if="form.has_tabs_drip" class="mb-3">There is no available tab to be dripped</p>
                                        <label for="tabdrip_notification" class="switch_option capsule_btn m-0 border-0">
                                            <h5 class="large">Notification</h5>
                                            <input type="checkbox" id="tabdrip_notification" :true-value="1" :false-value="0" v-model="form.has_tabs_drip_notification" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div v-show="form.has_tabs_drip_notification">
                                            <div class="section_wpr mb-2">
                                                <div class="edit_section">
                                                    <sending-method v-model="tabDrip.sending_method" />
                                                </div>
                                                <div v-if="tabDrip.sending_method == 1 || tabDrip.sending_method == 3">
                                                    <email-component v-if="loaded" v-model="tabDripEmail" :errors="errors" :handle-default-email="handleTabDripDefaultEmail" preview-module="playbook" ref="step-drip-email-component" />
                                                </div>
                                                <div v-if="tabDrip.sending_method == 2 || tabDrip.sending_method == 3" class="sms-component">
                                                    <sms-component v-if="loaded" v-model="tabDrip.sms_content" media-field-name="tab_drip_sms_media" :default-sms-handler="true" preview-module="playbook" :handle-default-sms="handleTabDripDefaultSms" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-show="tab == 'downloads'">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Downloads</h2>
                                </div>
                                <div class="color_container mt-2">
                                    <label for="downloads" class="switch_option capsule_btn m-0 border-0">
                                        <h5 class="large">Downloads</h5>
                                        <input type="checkbox" id="downloads" :true-value="1" :false-value="0" v-model="form.is_download" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div class="upload_image mt-2" v-show="form.is_download">
                                        <div id="step-download-dropzone" class="dropzone">
                                            <div class="dz-message" data-dz-message>
                                                <div>
                                                    <img src="@/assets/images/image2.png">
                                                    <h4>Click to Upload</h4>
                                                    <p>or drag and drop</p>
                                                </div>
                                            </div>
                                            <div v-for="(item, i) in fileSizeValidation" :key="i">
                                                <span class="text-danger" >{{item}}</span>
                                            </div>
                                        </div>
                                        <ul class="download_list mt-5" v-if="stepDownloads.length">
                                            <template v-for="(download, d) of stepDownloads" :key="d">
                                                <li :class="{ 'has-error': filenameValidation && isDownloadEdited && selectedDownload.id == download.id }">
                                                    <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                                        <i class="far fa-file-excel mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                                        <i class="far fa-file-image mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <h5 v-else-if="['pdf'].includes(download.ext)">
                                                        <i class="far fa-file-pdf mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                                        <i class="far fa-file-video mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                                        <i class="far fa-file-word mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                                        <i class="far fa-file-powerpoint mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                                        <i class="far fa-file-audio mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <h5 v-else>
                                                        <i class="far fa-file mr-1"></i>
                                                        <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                        <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                    </h5>
                                                    <span class="download-action w-70">
                                                        <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                                                            <i class="fa fa-check pointer" @click="handleDownloadRename()"></i>
                                                        </span>
                                                        <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                                                            <i class="fa fa-times pointer" @click="selectedDownload = {}; isDownloadEdited = false; filenameValidation = false; "></i>
                                                        </span>
                                                        <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-om-blue">
                                                            <i class="far fa-edit pointer" @click="handleEditDownload(download)"></i>
                                                        </span>
                                                        <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-danger p-0">
                                                            <i class="far fa-trash-alt pointer" @click="handleDownloadRemove(download.id)"></i>
                                                        </span>
                                                    </span>
                                                </li>
                                                <div class="w-100 text-danger download-error" v-if="filenameValidation && isDownloadEdited && selectedDownload.id == download.id">
                                                    The file name field is required.
                                                </div>
                                            </template>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <ul class="btn_list">
                            <li v-if="toggleAll" @click="openAllSection">Open All</li>
                            <li v-else @click="closeAllSection">Close All</li>
                            <li class="tag_setup" v-click-outside="close_gen_setup">
                                <i class="fas fa-cog pointer" @click="general_setup = !general_setup;"></i>
                                <div class="dropdown_wpr" :class="{'active' : general_setup}">
                                    <div class="setting_wpr">
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <div class="field_wpr has_suffix">
                                                    <Field autocomplete="off" type="text" name="step_url" v-model="form.step_url" readonly />
                                                    <span class="suffix pointer" @click="copyStepUrl">Copy</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="capsule_elm pt-2">
                                            <h5>Visible in draft mode with link</h5>
                                            <label for="draft" class="switch_option capsule_btn">
                                                <input type="checkbox" id="draft" :true-value="1" :false-value="0" v-model="form.url_active" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div class="content_wpr">
                            <!-- <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Live Step URL</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-2">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <div class="field_wpr has_suffix">
                                                        <Field autocomplete="off" type="text" name="step_url" v-model="form.step_url" readonly />
                                                        <span class="suffix pointer" @click="copyStepUrl">Copy</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <label for="draft" class="switch_option capsule_btn pt-4">
                                                <h5 class="large">Visible In Draft Mode With Link</h5>
                                                <input type="checkbox" id="draft" :true-value="1" :false-value="0" v-model="form.url_active" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="section_content w-100" :class="!preview ? 'w-100' : ''" @click="goto('cvrsection')" ref="cvrsetting">
                                <div class="section_header">
                                    <h2>Add a banner image at the top <span>Add a 200x1200 accent image at the top</span></h2>
                                    <span class="status" :style="`color: ${form.display_banner ? '#2f7eed' : '#999'};`">{{ form.display_banner ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-2">
                                        <div class="section_title m-0">
                                            <h4>Show</h4>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="inside_thumb" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="inside_thumb" :true-value="1" :false-value="0" v-model="form.display_banner" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="content_details mt-4">
                                            <image-library v-model="form.banner" image-type="playbook-step-banner" upload-text="Banner" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" :class="!preview ? 'w-100' : ''" @click="goto('mainsection')" ref="mainsetting">
                                <div class="section_header">
                                    <h2>Add featured media area <span>You can insert featured video content here</span></h2>
                                    <span class="status" :style="`color: ${form.show_main_video || form.show_additional_video ? '#2f7eed' : '#999'};`">{{ form.show_main_video || form.show_additional_video ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-2">
                                        <div class="section_title m-0">
                                            <h4>Show main video or image</h4>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="main-video" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="main-video" v-model="form.show_main_video" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="content_details mt-5">
                                            <content-thumbnail v-model="stepMainThumbnail" :errors="errors" :tab="tab"></content-thumbnail>
                                        </div> 
                                    </div>
                                    <div class="color_container mt-3" v-show="form.has_additional_video">
                                        <div class="section_title m-0">
                                            <h4>Show additional video or image</h4>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="additional-video" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="additional-video" v-model="form.show_additional_video" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="content_details mt-5">
                                            <content-thumbnail v-model="stepAdditionalThumbnail" :errors="errors" :reload="stepAdditionalThumbnailReload" :tab="tab"></content-thumbnail>
                                        </div>
                                    </div>
                                    <ul class="tabs_action mt-3">
                                        <li>
                                            <button type="button" v-if="form.has_additional_video == 0" class="add_btn" @click="form.has_additional_video = 1"><i class="fas fa-plus"></i>Add Additional Media</button>
                                            <button type="button" v-else class="add_btn" @click="form.has_additional_video = 0"><i class="fas fa-minus"></i>Remove Media</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Edit main content area <span>This is where your main content goes</span></h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="setting_wpr mt-2">
                                        <div class="form_grp p-0">
                                            <div class="group_item">
                                                <redactor v-model="form.description" name="description" :default-value="selectedStep.description" ref="step-editor"  :email-editor="false" :reset-watcher="stepContentResetWatcher" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container mt-3">
                                        <div class="section_title m-0">
                                            <h4>Additional Tabs <label>You can add tabbed content under your main content</label></h4>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="additional_tab" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="additional_tab" :true-value="1" :false-value="0" v-model="form.is_tabs" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="content_details">
                                            <div class="tabs_action mt-3">
                                                <button type="button" class="add_btn add_tabs" @click="addTab = !addTab"><i class="fas fa-plus"></i>Add Tab</button>
                                            </div>
                                            <div v-if="addTab">
                                                <Form @submit="handleAddTab" v-slot="{ errors }">
                                                    <div class="setting_wpr mb-3">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Title</label>
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.tab_title }">
                                                                    <Field autocomplete="off" type="text" name="tab_title" placeholder="ex: New Tab" v-model="tabForm.title" />
                                                                </div>
                                                                <ErrorMessage name="tab_title" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="action_wpr">
                                                            <button type="button" class="btn cancel_btn" @click="addTab = false">Cancel</button>
                                                            <button class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookStepTabSaveLoader"></i> {{ playbookStepTabSaveLoader ? 'Saving' : 'Save' }}</button>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                            <template v-if="stepTabs.length">
                                                <draggable v-model="stepTabs" tag="ul" item-key="id" class="tab_sec scr_tab" @change="handleTabSort">
                                                    <template #item="{element}">
                                                        <li class="move" @click="resetTabForm(element); activeTab = JSON.parse(JSON.stringify(element));" :class="{ active: selectedTab.id === element.id }">
                                                            {{ element.title }} <span class="delete pointer" @click="handleTabDelete(element.id)"><i class="fas fa-times"></i></span>
                                                        </li>
                                                    </template>
                                                </draggable>
                                                <div class="tab_item" v-show="selectedTab.id">
                                                    <div class="top_title">
                                                        <label for="display-tab-toggle" class="switch_option capsule_btn p-0">
                                                            <h5 class="large">Display Tab</h5>
                                                            <input type="checkbox" id="display-tab-toggle" :true-value="1" :false-value="0" @change="handleUpdateTab(selectedTab)" v-model="selectedTab.display_tab" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                    <div class="tab_details" v-show="selectedTab.display_tab">
                                                        <div class="setting_wpr">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Title</label>
                                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.tab_title }">
                                                                        <Field autocomplete="off" type="text" name="tab_title" placeholder="ex: New Tab" v-model="selectedTab.title" @focusout="handleUpdateTab(selectedTab)" />
                                                                    </div>
                                                                    <ErrorMessage name="tab_title" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="mt-4">
                                                                <h3 class="sub_heading2 mb-4">Image or Video</h3>
                                                                <content-thumbnail v-model="selectedTab.thumbnail" :errors="errors" :is-dynamic="true" :tab="tab" title="Video or image title Text"></content-thumbnail>
                                                            </div>
                                                            <div class="mt-4">
                                                                <h3 class="sub_heading2">Content</h3>
                                                                <redactor v-model="selectedTab.content" name="tab_content" :default-value="activeTab.content" ref="tab-editor" :reset-watcher="tabContentResetWatcher" :email-editor="false" />
                                                            </div>
                                                            <div class="form_grp mt-4">
                                                                <div class="group_item">
                                                                    <label class="input_label">Tab Background Color</label>
                                                                    <Field autocomplete="off" name="bg_color" v-model="selectedTab.bg_color" type="text" label="element color">
                                                                        <color-picker v-model="selectedTab.bg_color" :classes="{ 'has-error': errors.bg_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="bg_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Tab Text Color</label>
                                                                    <Field autocomplete="off" name="text_color" v-model="selectedTab.text_color" type="text" label="element color">
                                                                        <color-picker v-model="selectedTab.text_color" :classes="{ 'has-error': errors.text_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="text_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <label for="apply-to-all" class="switch_option capsule_btn m-0 border-0" v-show="stepTabs && stepTabs.length && (activeTab.id == stepTabs[0].id)">
                                                                <h5 class="large">Apply to all tabs</h5>
                                                                <input type="checkbox" id="apply-to-all" @click="confimApplyToAllTabs" :true-value="1" :false-value="0" v-model="form.apply_to_all" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- <div class="empty_items" v-else>No items found</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <!-- <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Add additional tabbed content <span>You can organize content using sub-steps</span></h2>
                                    <span class="status" :style="`color: ${form.is_tabs ? '#2f7eed' : '#999'};`">{{ form.is_tabs ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-2">
                                        <label for="additional_tab" class="switch_option capsule_btn p-0">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="additional_tab" :true-value="1" :false-value="0" v-model="form.is_tabs" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <template v-if="stepTabs.length">
                                            <draggable v-model="stepTabs" tag="ul" item-key="id" class="tab_sec scr_tab mb-3 mt-3" @change="handleTabSort">
                                                <template #item="{element}">
                                                    <li class="move" @click="resetTabForm(element); activeTab = element;" :class="{ active: selectedTab.id === element.id }">
                                                        {{ element.title }} <span class="delete pointer" @click="handleTabDelete(element.id)"><i class="fas fa-times"></i></span>
                                                    </li>
                                                </template>
                                            </draggable>
                                            <div class="tab_item" v-show="selectedTab.id">
                                                <div class="top_title">
                                                    <label for="display-tab-toggle" class="switch_option capsule_btn p-0">
                                                        <h5 class="large">Display Tab</h5>
                                                        <input type="checkbox" id="display-tab-toggle" :true-value="1" :false-value="0" @change="handleUpdateTab(selectedTab)" v-model="selectedTab.display_tab" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="tab_details" v-show="selectedTab.display_tab">
                                                    <div class="setting_wpr">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Title</label>
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.tab_title }">
                                                                    <Field autocomplete="off" type="text" name="tab_title" placeholder="ex: New Tab" v-model="selectedTab.title" @focusout="handleUpdateTab(selectedTab)" />
                                                                </div>
                                                                <ErrorMessage name="tab_title" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="mt-4">
                                                            <h3 class="sub_heading2 mb-4">Image or Video</h3>
                                                            <content-thumbnail v-model="selectedTab.thumbnail" :errors="errors" :is-dynamic="true" :tab="tab" title="Video or image title Text"></content-thumbnail>
                                                        </div>
                                                        <div class="mt-4">
                                                            <h3 class="sub_heading2">Content</h3>
                                                            <redactor v-model="selectedTab.content" name="tab_content" :default-value="activeTab.content" ref="tab-editor" :reset-watcher="tabContentResetWatcher" :email-editor="false" />
                                                        </div>
                                                        <div class="form_grp mt-4">
                                                            <div class="group_item">
                                                                <label class="input_label">Tab Background Color</label>
                                                                <Field autocomplete="off" name="bg_color" v-model="selectedTab.bg_color" type="text" label="element color">
                                                                    <color-picker v-model="selectedTab.bg_color" :classes="{ 'has-error': errors.bg_color }" />
                                                                </Field>
                                                                <ErrorMessage name="bg_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Tab Text Color</label>
                                                                <Field autocomplete="off" name="text_color" v-model="selectedTab.text_color" type="text" label="element color">
                                                                    <color-picker v-model="selectedTab.text_color" :classes="{ 'has-error': errors.text_color }" />
                                                                </Field>
                                                                <ErrorMessage name="text_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <label for="apply-to-all" class="switch_option capsule_btn m-0 border-0" v-show="stepTabs && stepTabs.length && (activeTab.id == stepTabs[0].id)">
                                                            <h5 class="large">Apply to all tabs</h5>
                                                            <input type="checkbox" id="apply-to-all" @click="confimApplyToAllTabs" :true-value="1" :false-value="0" v-model="form.apply_to_all" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <div class="empty_items" v-else>No items found</div>
                                        <div v-if="addTab">
                                            <Form @submit="handleAddTab" v-slot="{ errors }">
                                                <div class="setting_wpr mb-3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Title</label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.tab_title }">
                                                                <Field autocomplete="off" type="text" name="tab_title" placeholder="ex: New Tab" v-model="tabForm.title" />
                                                            </div>
                                                            <ErrorMessage name="tab_title" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="action_wpr">
                                                        <button type="button" class="btn cancel_btn" @click="addTab = false">Cancel</button>
                                                        <button class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="playbookStepTabSaveLoader"></i> {{ playbookStepTabSaveLoader ? 'Saving' : 'Save' }}</button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <div class="tabs_action">
                                            <button type="button" class="add_tabs" @click="addTab = !addTab"><i class="fas fa-plus"></i>Add Tab</button>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Activate drip release <span>Schedule the release of your content</span></h2>
                                    <span class="status" :style="`color: ${form.drip_schedule || form.drip_notification || form.has_tabs_drip || form.has_tabs_drip_notification ? '#2f7eed' : '#999'};`">{{ form.drip_schedule || form.drip_notification || form.has_tabs_drip || form.has_tabs_drip_notification ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <!-- <div class="toggle_content">
                                    <div class="step_drips">
                                        <div class="section_title m-0">
                                            <h4>Step Drip Schedule</h4>
                                            <a class="toggle_details" @click="toggleDetails($event, '.step_drips')">Open</a>
                                            <label for="drip_schedule" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="drip_schedule" :true-value="1" :false-value="0" v-model="form.drip_schedule" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="drip_details">
                                            <drip-component :errors="errors" v-model="stepDripForm" />
                                            <label for="drip_notification" class="switch_option capsule_btn py-4">
                                                <h5 class="large">Notification</h5>
                                                <input type="checkbox" id="drip_notification" :true-value="1" :false-value="0" v-model="form.drip_notification" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="section_wpr mb-2" v-show="form.drip_notification">
                                                <div class="edit_section">
                                                    <sending-method v-model="form.drip_notification_method" />
                                                </div>
                                                <div v-if="form.drip_notification_method == 1 || form.drip_notification_method == 3">
                                                    <email-component v-if="loaded" v-model="stepDripEmail" :errors="errors" :handle-default-email="handleStepDripDefaultEmail" preview-module="playbook" ref="step-drip-email-component" />
                                                </div>
                                                <div v-if="form.drip_notification_method == 2 || form.drip_notification_method == 3" class="sms-component">
                                                    <sms-component v-if="loaded" v-model="form.drip_notification_sms" media-field-name="drip_notification_sms_media" :default-sms-handler="true" preview-module="playbook" :handle-default-sms="handleStepDripDefaultSms" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="step_drips">
                                        <div class="section_title m-0">
                                            <h4>Tabs Drip Schedule</h4>
                                            <a class="toggle_details" @click="toggleDetails($event, '.step_drips')">Open</a>
                                            <label for="tabdrip_schedule" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="tabdrip_schedule" :true-value="1" :false-value="0" v-model="form.has_tabs_drip" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="drip_details">
                                            <template v-if="stepTabs.length">
                                                <div class="step_drips tabs" v-for="(stepTab, t) of stepTabs" :key="t">
                                                    <div class="section_title m-0">
                                                        <h4>{{ stepTab.title }}</h4>
                                                        <a class="toggle_details" @click="toggleDetails($event, '.step_drips')">Open</a>
                                                    </div>
                                                    <div class="drip_details">
                                                        <drip-component :errors="errors" v-model="tabDripForm[t]"/>
                                                    </div>
                                                </div>
                                            </template>
                                            <p v-else class="mb-3">There is no available tab to be dripped</p>
                                            <label for="tabdrip_notification" class="switch_option capsule_btn mt-3 mb-2 border-0">
                                                <h5 class="large">Notification</h5>
                                                <input type="checkbox" id="tabdrip_notification" :true-value="1" :false-value="0" v-model="form.has_tabs_drip_notification" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-show="form.has_tabs_drip_notification">
                                                <div class="section_wpr mb-2">
                                                    <div class="edit_section">
                                                        <sending-method v-model="tabDrip.sending_method" />
                                                    </div>
                                                    <div v-if="tabDrip.sending_method == 1 || tabDrip.sending_method == 3">
                                                        <email-component v-if="loaded" v-model="tabDripEmail" :errors="errors" :handle-default-email="handleTabDripDefaultEmail" preview-module="playbook" ref="step-drip-email-component" />
                                                    </div>
                                                    <div v-if="tabDrip.sending_method == 2 || tabDrip.sending_method == 3" class="sms-component">
                                                        <sms-component v-if="loaded" v-model="tabDrip.sms_content" media-field-name="tab_drip_sms_media" :default-sms-handler="true" preview-module="playbook" :handle-default-sms="handleTabDripDefaultSms" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                
                                <div class="toggle_content">
                                    <div class="color_container mt-2">
                                        <ul class="tab_sec mb-4">
                                            <li @click="dripTab = 'step'" :class="{ active: dripTab === 'step' }">Step Drip</li>
                                            <li @click="dripTab = 'tab'" :class="{ active: dripTab === 'tab' }">Tabs Drip</li>
                                        </ul>
                                        <div v-show="dripTab === 'step'">
                                            <div class="item_container">
                                                <div class="section_title mt-2">
                                                    <h4>Schedule</h4>
                                                    <a class="toggle_details" @click="toggleTabDetails($event, '.item_container')">Open</a>
                                                    <label for="drip_schedule" class="switch_option capsule_btn m-0 border-0">
                                                        <input type="checkbox" id="drip_schedule" :true-value="1" :false-value="0" v-model="form.drip_schedule" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="content_details">
                                                    <div class="tab_content">
                                                        <drip-component :errors="errors" v-model="stepDripForm" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="item_container">
                                                <div class="section_title mt-2">
                                                    <h4>{{form.drip_notification ? 'Sending Method' : 'Notifications?'}}</h4>
                                                    <label for="drip_notification" class="switch_option capsule_btn m-0 border-0">
                                                        <input type="checkbox" id="drip_notification" :true-value="1" :false-value="0" v-model="form.drip_notification" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="section_wpr mb-2" v-show="form.drip_notification">
                                                    <div class="edit_section border">
                                                        <sending-method v-model="form.drip_notification_method" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-show="dripTab === 'tab'">
                                            <div class="item_container">
                                                <div class="section_title mt-2">
                                                    <h4>Schedule</h4>
                                                    <a class="toggle_details" @click="toggleTabDetails($event, '.item_container')">Open</a>
                                                    <label for="tabdrip_schedule" class="switch_option capsule_btn m-0 border-0">
                                                        <input type="checkbox" id="tabdrip_schedule" :true-value="1" :false-value="0" v-model="form.has_tabs_drip" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="content_details">
                                                    <template v-if="stepTabs.length">
                                                        <ul class="tab_sec scr_tab">
                                                            <template v-for="(stepTab, t) of stepTabs" :key="t">
                                                                <li class="px-0" @click="selectedTabDrip = stepTab" :class="{ active: selectedTabDrip.id == stepTab.id }">{{ stepTab.title }}</li>
                                                            </template>
                                                        </ul>
                                                        <div class="tab_content">
                                                            <span v-for="(dripForm, df) of tabDripForm" :key="df">
                                                                <drip-component :errors="errors" v-model="tabDripForm[df]" v-show="selectedTabDrip.id == dripForm.id" />
                                                            </span>
                                                        </div>
                                                    </template>
                                                    <p v-else class="mb-3">There is no available tab to be dripped</p>
                                                </div>
                                            </div>
                                            <div class="item_container">
                                                <div class="section_title mt-2">
                                                    <h4>{{form.has_tabs_drip_notification ? 'Sending Method' : 'Notifications?'}}</h4>
                                                    <label for="tabdrip_notification" class="switch_option capsule_btn m-0 border-0">
                                                        <input type="checkbox" id="tabdrip_notification" :true-value="1" :false-value="0" v-model="form.has_tabs_drip_notification" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <div class="section_wpr mb-2" v-show="form.has_tabs_drip_notification">
                                                    <div class="edit_section border">
                                                        <sending-method v-model="tabDrip.sending_method" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3" v-show="form.drip_notification && dripTab === 'step'">
                                        <div v-if="form.drip_notification_method == 1 || form.drip_notification_method == 3">
                                            <email-component v-if="loaded" v-model="stepDripEmail" :errors="errors" :handle-default-email="handleStepDripDefaultEmail" preview-module="playbook" ref="step-drip-email-component" />
                                        </div>
                                        <div v-if="form.drip_notification_method == 2 || form.drip_notification_method == 3" class="sms-component">
                                            <sms-component v-if="loaded" v-model="form.drip_notification_sms" media-field-name="drip_notification_sms_media" :default-sms-handler="true" preview-module="playbook" :handle-default-sms="handleStepDripDefaultSms" />
                                        </div>
                                    </div>
                                    <div class="mt-3" v-show="form.has_tabs_drip_notification && dripTab === 'tab'">
                                        <div v-if="tabDrip.sending_method == 1 || tabDrip.sending_method == 3">
                                            <email-component v-if="loaded" v-model="tabDripEmail" :errors="errors" :handle-default-email="handleTabDripDefaultEmail" preview-module="playbook" ref="step-drip-email-component" />
                                        </div>
                                        <div v-if="tabDrip.sending_method == 2 || tabDrip.sending_method == 3" class="sms-component">
                                            <sms-component v-if="loaded" v-model="tabDrip.sms_content" media-field-name="tab_drip_sms_media" :default-sms-handler="true" preview-module="playbook" :handle-default-sms="handleTabDripDefaultSms" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Include a download area <span>Add value by including worksheets, audio, etc</span></h2>
                                    <span class="status" :style="`color: ${form.is_download ? '#2f7eed' : '#999'};`">{{ form.is_download ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-2">
                                        <div class="section_title m-0">
                                            <h4>Show</h4>
                                            <a class="toggle_details" @click="toggleDetails($event)">Open</a>
                                            <label for="downloads" class="switch_option capsule_btn p-0 border-0">
                                                <input type="checkbox" id="downloads" :true-value="1" :false-value="0" v-model="form.is_download" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="content_details">
                                            <div class="upload_image mt-4">
                                                <div id="step-download-dropzone" class="dropzone">
                                                    <div class="dz-message" data-dz-message>
                                                        <div>
                                                            <img src="@/assets/images/image2.png">
                                                            <h4>Click to Upload</h4>
                                                            <p>or drag and drop</p>
                                                        </div>
                                                    </div>
                                                    <div v-for="(item, i) in fileSizeValidation" :key="i">
                                                        <span class="text-danger" >{{item}}</span>
                                                    </div>
                                                </div>
                                                <ul class="download_list mt-5" v-if="stepDownloads.length">
                                                    <template v-for="(download, d) of stepDownloads" :key="d">
                                                        <li :class="{ 'has-error': filenameValidation && isDownloadEdited && selectedDownload.id == download.id }">
                                                            <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                                                <i class="far fa-file-excel"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                                                <i class="far fa-file-image"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <h5 v-else-if="['pdf'].includes(download.ext)">
                                                                <i class="far fa-file-pdf"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                                                <i class="far fa-file-video"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                                                <i class="far fa-file-word"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                                                <i class="far fa-file-powerpoint"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                                                <i class="far fa-file-audio"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <h5 v-else>
                                                                <i class="far fa-file"></i>
                                                                <input v-if="isDownloadEdited && selectedDownload.id == download.id" type="text" v-model="selectedDownload.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                                <input v-else type="text" :value="download.filename" class="download-editor" :disabled="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)">
                                                            </h5>
                                                            <span class="download-action w-70">
                                                                <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                                                                    <i class="fa fa-check pointer" @click="handleDownloadRename()"></i>
                                                                </span>
                                                                <span v-if="isDownloadEdited && selectedDownload.id == download.id" class="text-om-blue">
                                                                    <i class="fa fa-times pointer" @click="selectedDownload = {}; isDownloadEdited = false; filenameValidation = false; "></i>
                                                                </span>
                                                                <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-om-blue">
                                                                    <i class="far fa-edit pointer" @click="handleEditDownload(download)"></i>
                                                                </span>
                                                                <span v-if="!isDownloadEdited || (isDownloadEdited && selectedDownload.id != download.id)" class="text-danger p-0">
                                                                    <i class="far fa-trash-alt pointer" @click="handleDownloadRemove(download.id)"></i>
                                                                </span>
                                                            </span>
                                                        </li>
                                                        <div class="w-100 text-danger download-error" v-if="filenameValidation && isDownloadEdited && selectedDownload.id == download.id">
                                                            The file name field is required.
                                                        </div>
                                                    </template>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Save as Smart Step <span></span></h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container mt-2">
                                        <div class="content_details show">
                                            <label for="smart" class="switch_option capsule_btn">
                                                <h5 class="large">Save as smart step</h5>
                                                <input type="checkbox" id="smart" :true-value="1" :false-value="0" v-model="form.is_smart_step" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="setting_wpr" v-if="form.is_smart_step">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Smart Step Title</label>
                                                        <div class="field_wpr m-0" :class="{ 'has-error': errors.smart_step_title }">
                                                            <Field autocomplete="off" type="text" name="smart_step_title" placeholder="ex: Smart Step" v-model="form.smart_step_title" />
                                                        </div>
                                                        <ErrorMessage name="smart_step_title" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true" v-if="tab !== 'drip'">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="playbookStepSaveLoader" type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button :disabled="playbookStepSaveLoader" class="btn save_btn" id="save-step-button"><i class="fa fa-spin fa-spinner" v-if="playbookStepSaveLoader"></i> {{ playbookStepSaveLoader ? 'Updating' : 'Update' }}</button>
                        <button :disabled="playbookStepDuplicateLoader" type="button" class="btn save_btn" id="duplicate-step-button" @click="handleDuplicatePlaybookStep()"><i class="fa fa-spin fa-spinner" v-if="playbookStepDuplicateLoader"></i> {{ playbookStepDuplicateLoader ? 'Duplicating' : 'Duplicate' }}</button>
                        <button :disabled="playbookStepSaveLoader" type="button" @click="handleStepDelete(selectedStep.id)" class="btn danger_btn">Delete</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="tab != 'drip'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_step" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_step" v-model="stepPreview" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell area_body_v2">
                    <div class="content_area">
                        <div class="dashboard_content">
                            <div class="client_card border-0">
                                <div class="card_header" :style="`background: ${selectedPlaybook.header_bgcolor}; color: ${selectedPlaybook.header_textcolor};`">
                                    <nav>
                                        <!-- <h4>{{ selectedPlaybook.company_branding }}</h4> -->
                                        <img v-if="selectedPlaybook.company_logo && selectedPlaybook.company_logo != null" class="logo_img" :src="selectedPlaybook.company_logo" alt="logo">
                                        <img v-else class="logo_img" :src="require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                        <a>
                                            <h4>Dashboard</h4>
                                        </a>
                                    </nav>
                                    <div class="user_box" @click="accountDrop = !accountDrop">
                                        <img :src="selectedPlaybook.author ? selectedPlaybook.author : user.profile_pic" :alt="user.full_name">
                                        <div class="user_info">
                                            <h5 :style="`color: ${selectedPlaybook.header_textcolor};`">{{ user.full_name }}</h5>
                                        </div>
                                        <ul class="account_drop" :class="{active : accountDrop}">
                                            <li><i class="fas fa-home"></i> Primary Location</li>
                                            <li><i class="far fa-user"></i> Profile Info</li>
                                            <li><i class="far fa-file"></i> My Files</li>
                                            <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="card_body" v-show="selectedPlaybook.display_cover" ref="cvrsection" @click="setting('cvrsetting')" :class="{'cover-image-sm' : selectedPlaybook.cover_type == 1}">
                                    <!-- <div class="cover-image-sm"> -->
                                        <picture v-if="(selectedPlaybook.cover_type == 2 || selectedPlaybook.cover_type == 3)">
                                            <source v-if="selectedPlaybook.has_small_screen_cover && selectedPlaybook.ep_logo_small != null && selectedPlaybook.ep_logo_small != '' && selectedPlaybook.ep_logo_small != 'null'" :srcset="`${selectedPlaybook.ep_logo_small}`" media="(max-width: 768px)">
                                            <img :src="selectedPlaybook.has_small_screen_cover && selectedPlaybook.ep_logo_small ? selectedPlaybook.ep_logo_small : (selectedPlaybook.ep_logo ? selectedPlaybook.ep_logo : require('@/assets/images/thumb/default-cover-3-2.svg'))" alt="" class="banner_img">
                                        </picture>
                                        <span v-if="selectedPlaybook.cover_type == 1" class="overlay" :style="`background-color: ${selectedPlaybook.cover_background_color ? selectedPlaybook.cover_background_color : selectedPlaybook.opac_overlay_color};`"></span>
                                        <span v-else-if="selectedPlaybook.cover_type == 2 && selectedPlaybook.has_cover_overlay" class="overlay" :style="`background-color: ${selectedPlaybook.overlay_color}; opacity: ${(selectedPlaybook.opac_overlay_color == 'transparent' || !selectedPlaybook.has_cover_overlay || selectedPlaybook.overlay_opacity == 0) ? '0': ((selectedPlaybook.overlay_opacity / 100 ))};`"></span>
                                        <span v-else-if="selectedPlaybook.cover_type == 3 && selectedPlaybook.has_cover_overlay" class="overlay" :style="`background-color: ${selectedPlaybook.overlay_color}; opacity: ${(selectedPlaybook.opac_overlay_color == 'transparent' || !selectedPlaybook.has_cover_overlay || selectedPlaybook.overlay_opacity == 0) ? '0': ((selectedPlaybook.overlay_opacity / 100 ))};`"></span>
                                        <div class="banner_content">
                                            <h4 class="shadow-none" v-if="selectedPlaybook.cover_type == 1 || selectedPlaybook.cover_type == 2" :style="`font-size: ${selectedPlaybook.headline_setting.font_size / 3 < 20 ? 20 : selectedPlaybook.headline_setting.font_size / 3}px; line-height: ${selectedPlaybook.headline_setting.font_size / 3 < 20 ? 26 : (parseInt(selectedPlaybook.headline_setting.font_size) + 20) / 3}px; font-weight: ${selectedPlaybook.headline_setting.font_weight}; font-family: ${selectedPlaybook.headline_setting.font_family}; color: ${selectedPlaybook.cover_textcolor}; ${selectedPlaybook.headline_setting.has_shadow ? 'text-shadow: ' + selectedPlaybook.headline_setting.shadow_x +'px ' + selectedPlaybook.headline_setting.shadow_y + 'px ' + selectedPlaybook.headline_setting.shadow_blur + 'px ' + selectedPlaybook.headline_setting.shadow_color : 'none'}`">{{ selectedPlaybook.headline }}</h4>
                                            <h1 class="shadow-none" v-if="selectedPlaybook.cover_type == 1 || selectedPlaybook.cover_type == 2" :style="`font-size: ${selectedPlaybook.subheadline_setting.font_size / 3 < 11 ? 11 : selectedPlaybook.subheadline_setting.font_size / 3}px; line-height: ${selectedPlaybook.subheadline_setting.font_size / 3 < 11 ? 16 : (parseInt(selectedPlaybook.subheadline_setting.font_size) + 20) / 3}px; font-weight: ${selectedPlaybook.subheadline_setting.font_weight}; font-family: ${selectedPlaybook.subheadline_setting.font_family}; color: ${selectedPlaybook.subheadline_setting.font_color}; ${selectedPlaybook.subheadline_setting.has_shadow ? 'text-shadow: ' + selectedPlaybook.subheadline_setting.shadow_x +'px ' + selectedPlaybook.subheadline_setting.shadow_y + 'px ' + selectedPlaybook.subheadline_setting.shadow_blur + 'px ' + selectedPlaybook.subheadline_setting.shadow_color : 'none'}`">{{ selectedPlaybook.sub_headline }}</h1>
                                        </div>
                                    <!-- </div> -->
                                </div>
                            </div>
                            <ul class="mobile_menu w-100">
                                <li><i class="fas fa-angle-left"></i>Back</li>
                                <li @click="openSlide('offer')" v-if="selectedPlaybook.display_playbook_offer">
                                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.82682 7.14098C4.55389 5.71402 5.71402 4.55388 7.14098 3.82682C8.09748 3.33947 9.09148 3.15918 10.0908 3.07753C11.0377 3.00018 12.1873 3.0002 13.5199 3.00025L25.1389 3.00013C26.1675 2.99917 27.0742 2.99833 27.9552 3.20979C28.7289 3.39556 29.4686 3.70199 30.1471 4.11777C30.9196 4.59109 31.5602 5.23292 32.2869 5.96092L51.8948 25.569C53.3337 27.0079 54.5213 28.1953 55.4096 29.2418C56.3322 30.3285 57.0795 31.4284 57.5052 32.7379C58.1649 34.7688 58.1649 36.9564 57.5052 38.9873C57.0795 40.2967 56.3322 41.3966 55.4096 42.4833C54.5213 43.5298 53.3337 44.7172 51.8948 46.156L46.1561 51.8948C44.7172 53.3337 43.5296 54.5213 42.4833 55.4096C41.3966 56.3322 40.2967 57.0797 38.987 57.5052C36.9564 58.1649 34.7688 58.1649 32.7379 57.5052C31.4285 57.0797 30.3286 56.3322 29.2418 55.4096C28.1953 54.5213 27.008 53.3337 25.569 51.8948L5.96092 32.2869C5.23292 31.5601 4.59107 30.9196 4.11778 30.1473C3.70197 29.4688 3.39557 28.7289 3.20979 27.9551C2.99832 27.0744 2.99917 26.1674 3.00013 25.1389L3.00026 13.5199C3.00021 12.1873 3.00017 11.0377 3.07754 10.0909C3.15919 9.09148 3.33947 8.09748 3.82682 7.14098ZM20.6954 15.6393C17.9032 15.6393 15.6396 17.9029 15.6396 20.6951C15.6396 23.4873 17.9032 25.7508 20.6954 25.7508C23.4876 25.7508 25.7511 23.4873 25.7511 20.6951C25.7511 17.9029 23.4876 15.6393 20.6954 15.6393Z" fill="#999999"/>
                                    </svg>
                                    Offer
                                </li>
                                <li @click="openSlide('instructor')" v-if="selectedPlaybook.display_coach">
                                    <svg width="50" height="54" viewBox="0 0 50 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.0717 43.5574C7.3429 36.5812 15.646 32.0607 25.0001 32.0607C34.3541 32.0607 42.6572 36.5812 47.9283 43.5574C47.9521 43.5889 47.9761 43.6207 48.0001 43.6522C48.4533 44.2517 48.9393 44.8943 49.2866 45.5144C49.7068 46.2649 50.0186 47.1329 49.9991 48.1888C49.9836 49.0373 49.7266 49.8319 49.4143 50.4592C49.102 51.0865 48.6232 51.7705 47.9555 52.2947C47.0648 52.9936 46.0952 53.241 45.2338 53.3441C44.4783 53.4347 43.5827 53.4347 42.6824 53.4344C42.6417 53.4344 42.6011 53.4344 42.5605 53.4344H7.43943C7.39885 53.4344 7.35827 53.4344 7.31771 53.4344C6.41734 53.4347 5.5218 53.4347 4.76613 53.3441C3.9048 53.241 2.9352 52.9936 2.04467 52.2947C1.37687 51.7705 0.898042 51.0865 0.585771 50.4592C0.273501 49.8319 0.0163741 49.0373 0.000851407 48.1888C-0.0184651 47.1329 0.293084 46.2649 0.713453 45.5144C1.06072 44.8943 1.54671 44.2517 1.99991 43.6522C2.02396 43.6207 2.04787 43.5889 2.0717 43.5574Z" fill="#999999"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.3047 14.6945C10.3047 6.57893 16.8836 0 24.9992 0C33.1146 0 39.6937 6.57893 39.6937 14.6945C39.6937 22.8101 33.1146 29.3889 24.9992 29.3889C16.8836 29.3889 10.3047 22.8101 10.3047 14.6945Z" fill="#999999"/>
                                    </svg>
                                    Coach
                                </li>
                                <li @click="openSlide('menu')">
                                    <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M47.8032 2.19668C50.7321 5.12558 50.7321 9.8743 47.8032 12.8032C44.8742 15.7321 40.1257 15.7321 37.1967 12.8032C34.2678 9.8743 34.2678 5.12558 37.1967 2.19668C40.1257 -0.732226 44.8742 -0.732226 47.8032 2.19668Z" fill="#999999"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M47.4997 22.0136C47.4997 20.9916 47.4997 20.4806 47.3337 20.1916C47.1672 19.9012 46.9979 19.7579 46.6839 19.6416C46.3714 19.5258 45.7739 19.6261 44.5792 19.8266C40.728 20.4728 36.6325 19.3103 33.661 16.3387C30.6893 13.3671 29.5268 9.27152 30.173 5.42042C30.3735 4.22555 30.4738 3.62813 30.358 3.31563C30.2418 3.00176 30.0985 2.83249 29.808 2.66586C29.519 2.49994 29.008 2.49994 27.986 2.49994H14.3966C12.3843 2.49991 10.7233 2.49989 9.37038 2.61041C7.96514 2.72524 6.6734 2.97164 5.46006 3.58988C3.57845 4.5486 2.04866 6.07839 1.08994 7.96C0.471698 9.17332 0.225299 10.4651 0.1105 11.8703C-4.93877e-05 13.2233 -2.42479e-05 14.8841 7.51906e-07 16.8965V35.603C-2.42479e-05 37.6154 -4.93877e-05 39.2762 0.1105 40.6292C0.225299 42.0344 0.471698 43.3261 1.08994 44.5396C2.04866 46.4211 3.57845 47.9509 5.46006 48.9096C6.6734 49.5279 7.96514 49.7743 9.37038 49.8891C10.7233 49.9996 12.3842 49.9996 14.3966 49.9996H33.103C35.1155 49.9996 36.7762 49.9996 38.1292 49.8891C39.5345 49.7743 40.8262 49.5279 42.0397 48.9096C43.9212 47.9509 45.4509 46.4211 46.4097 44.5396C47.0279 43.3261 47.2744 42.0344 47.3892 40.6292C47.4997 39.2762 47.4997 37.6154 47.4997 35.603V22.0136ZM9.99993 27.4998C9.99993 26.119 11.1192 24.9998 12.4999 24.9998H32.4998C33.8805 24.9998 34.9997 26.119 34.9997 27.4998C34.9997 28.8805 33.8805 29.9997 32.4998 29.9997H12.4999C11.1192 29.9997 9.99993 28.8805 9.99993 27.4998ZM9.99993 37.4997C9.99993 36.1189 11.1192 34.9997 12.4999 34.9997H27.4998C28.8805 34.9997 29.9998 36.1189 29.9998 37.4997C29.9998 38.8804 28.8805 39.9997 27.4998 39.9997H12.4999C11.1192 39.9997 9.99993 38.8804 9.99993 37.4997Z" fill="#999999"/>
                                    </svg>
                                    Menu
                                </li>
                            </ul>
                            <div class="left_side">
                                <!-- <div class="progress_status" v-if="selectedPlaybook.display_progressbar">
                                    <div class="status">
                                        <span style="width:50%;"></span>
                                    </div>
                                    <h5>2 of {{playbookSteps.length}} Steps Completed</h5>
                                </div> -->
                                <!-- <div class="section_item progress_sec border-item" v-if="selectedPlaybook.display_progressbar">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`">0 of {{playbookSteps.length}} Steps Completed</h3>
                                    <div class="status mb-2"><span style="width:50%;"></span></div>
                                </div> -->
                                <div class="content_details">
                                    <div class="section_item p-0" v-if="form.display_banner && form.banner" ref="ttlsection" @click="setting('ttlsetting')">
                                        <div class="step_banner">
                                            <h3 class="mb-3">{{ form.title }}</h3>
                                            <img :src="form.banner" alt="" v-if="form.banner">
                                        </div>
                                    </div>
                                    <div class="section_item video_wpr p-0 m-0" v-if="form.show_main_video" ref="mainsection" @click="setting('mainsetting')">
                                        <h3 class="mb-3" v-if="stepMainThumbnail.title">{{ stepMainThumbnail.title }}</h3>
                                        <div v-if="stepMainThumbnail.type == 'embed' && stepMainThumbnail.embed" v-html="stepMainThumbnail.embed" class="player_wpr mb-3"></div>
                                        <div v-if="stepMainThumbnail.type == 'url' && stepMainThumbnail.url" v-html="parseEmbedCode(stepMainThumbnail.url)" class="player_wpr mb-3"></div>
                                        <img v-if="stepMainThumbnail.type == 'image'" :src="stepMainThumbnail.image" alt="" class="mb-3">
                                        <img v-if="stepMainThumbnail.type == 'embed' && !stepMainThumbnail.embed || stepMainThumbnail.type == 'url' && !stepMainThumbnail.url || stepMainThumbnail.type == 'image' && !stepMainThumbnail.image" src="@/assets/images/thumb/video-placeholder.svg" alt="">
                                    </div>
                                    <div v-if="form.has_additional_video && form.show_additional_video" class="section_item video_wpr p-0 m-0" ref="mainsection" @click="setting('mainsetting')">
                                        <h3 class="mb-3" v-if="stepAdditionalThumbnail.title">{{ stepAdditionalThumbnail.title }}</h3>
                                        <div v-if="stepAdditionalThumbnail.type == 'embed' && stepAdditionalThumbnail.embed" v-html="stepAdditionalThumbnail.embed" class="player_wpr mb-3"></div>
                                        <div v-else-if="stepAdditionalThumbnail.type == 'url' && stepAdditionalThumbnail.url" v-html="parseEmbedCode(stepAdditionalThumbnail.url)" class="player_wpr mb-3"></div>
                                        <img v-else-if="stepAdditionalThumbnail.type == 'image'" :src="stepAdditionalThumbnail.image" alt="" class="mb-3">
                                        <img v-if="stepAdditionalThumbnail.type == 'embed' && !stepAdditionalThumbnail.embed || stepAdditionalThumbnail.type == 'url' && !stepAdditionalThumbnail.url || stepAdditionalThumbnail.type == 'image' && !stepAdditionalThumbnail.image" src="@/assets/images/thumb/video-placeholder.svg" alt="">
                                    </div>
                                    <div class="section_item p-0">
                                        <!-- <div class="description">
                                            <div class="desc_info"> -->
                                                <h3 v-if="!(form.display_banner && form.banner)" :style="`color: ${selectedPlaybook.title_text_color};`">{{ form.title }}</h3>
                                            <!-- </div>
                                        </div> -->
                                        <p class="para" v-html="form.description"></p>
                                    </div>
                                    <div class="section_item pt-2 pb-2"  v-if="form.is_tabs">
                                        <ul class="tabs" @mousewheel.self="scrollFunc($event)">
                                            <template v-for="(stepTab, t) of stepTabs" :key="t">
                                                <li v-if="stepTab.display_tab" :style="(selectedTabDrip.id === stepTab.id || stepTabs.length == 1) ? `background: ${stepTab.bg_color ? stepTab.bg_color : '#D4D4D4'}; color: ${stepTab.text_color ? stepTab.text_color : '#2C3E50'};` : ''" @click="activeTabPreview = stepTab; selectedTabDrip = stepTab; tabAutoSave = true;" :class="{ 'active': selectedTabDrip.id == stepTab.id || stepTabs.length == 1 }">{{ stepTab.title }}</li>
                                            </template>
                                        </ul>
                                        <h3 v-if="activeTabPreview.thumbnail && activeTabPreview.thumbnail.title">{{ activeTabPreview.thumbnail.title }}</h3>
                                        <div v-if="activeTabPreview.thumbnail && (activeTabPreview.thumbnail.image || activeTabPreview.thumbnail.url || activeTabPreview.thumbnail.embed)" class="video_wpr">
                                            <div class="player_wpr" v-if="activeTabPreview.thumbnail.type == 'embed'" v-html="activeTabPreview.thumbnail.embed"></div>
                                            <div class="player_wpr" v-else-if="activeTabPreview.thumbnail.type == 'url'" v-html="parseEmbedCode(activeTabPreview.thumbnail.url)"></div>
                                            <img v-else-if="activeTabPreview.thumbnail.type == 'image'" :src="activeTabPreview.thumbnail.image" alt="">
                                        </div>
                                        <p class="para mb-4 tab-content" v-html="activeTabPreview.content"></p>
                                        <button type="button" class="tab_status" v-if="stepTabs.length" :style="`color: ${selectedPlaybook.step_complete_button ? selectedPlaybook.step_complete_button.text_color : null};`"><u>Mark tab complete</u> <i class="fas fa-long-arrow-alt-right"></i></button>
                                    </div>
                                    <div class="section_item mb-2 border-item downloads" v-if="form.is_download && stepDownloads.length">
                                        <h3 :style="`color: ${selectedPlaybook.title_text_color};`">Downloads</h3>
                                        <ul class="preview_section download_list">
                                            <li v-for="(download, d) of stepDownloads" :key="d">
                                                <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                                    <i class="far fa-file-excel"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                                    <i class="far fa-file-image"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['pdf'].includes(download.ext)">
                                                    <i class="far fa-file-pdf"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                                    <i class="far fa-file-video"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                                    <i class="far fa-file-word"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                                    <i class="far fa-file-powerpoint"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                                    <i class="far fa-file-audio"></i> {{ download.filename }}
                                                </h5>
                                                <h5 v-else>
                                                    <i class="far fa-file"></i> {{ download.filename }}
                                                </h5>
                                                <span>
                                                    <i class="fas fa-cloud-download-alt pointer"></i>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="section_item py-5">
                                    <button type="button" class="steps_status" :style="`background: ${selectedPlaybook.step_complete_button ? selectedPlaybook.step_complete_button.bg_color : null}; color: ${selectedPlaybook.step_complete_button ? selectedPlaybook.step_complete_button.text_color : null};`">Mark Complete</button>
                                </div>
                                <div class="section_item faq pt-2 pb-0" v-if="selectedPlaybook.show_faq">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`">Playbook FAQ's</h3>
                                    <ul class="faq_list">
                                        <li @click="faqAcc($event)" v-for="(faq, f) in selectedPlaybook.faqs" :key="f" class="small-preview">
                                            <h5 class="faq-acc-header" :style="`background: ${selectedPlaybook.faq_settings.btn_color}; color: ${selectedPlaybook.faq_settings.btn_text_color};`">{{faq.question}} <i class="fas fa-angle-down"></i></h5>
                                            <p v-html="faq.answer"></p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="right_side">
                                <div class="section_item step_listing">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`" class="mb-4">Course Content</h3>
                                    <ul>
                                        <li class="step_card" v-for="(step, s) in playbookSteps" :key="s">
                                            <template v-if="!step.is_draft">
                                                <div class="items">
                                                    <div class="thumb" v-if="selectedPlaybook.has_step_thumb">
                                                        <img :src="step.thumbnail ? step.thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                                    </div>
                                                    <div class="step_info">
                                                        <h5>{{ step.title }}</h5>
                                                        <p>{{ step.thumb_desc && step.thumb_desc.length > 60 ? step.thumb_desc.substr(0, 60) + '...' : step.thumb_desc }}</p>
                                                    </div>
                                                </div>
                                                <div class="step_lock" :style="`background: ${selectedPlaybook.step_locked_bg}; color: ${selectedPlaybook.step_locked_text_color};`" v-if="selectedPlaybook.must_be_completed && s > unlockedStep"><i class="fas fa-lock" :style="`border-color: ${selectedPlaybook.step_locked_text_color};`"></i></div>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                                <!-- <div class="section_item progress_sec border-item" v-if="selectedPlaybook.display_progressbar">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`">0 of 2 Steps Completed</h3>
                                    <div class="status mb-2"><span style="width:50%;"></span></div>
                                </div> -->
                                <div class="section_item product_card" v-if="selectedPlaybook.display_playbook_offer">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`" v-if="selectedPlaybook.display_offer_headline && selectedPlaybook.offer_headline">{{ selectedPlaybook.offer_headline }}</h3>
                                    <img :src="selectedPlaybook.offer_image ? selectedPlaybook.offer_image : require('@/assets/images/thumb/default-step-cover.svg')" alt="product-image">
                                </div>
                                <div class="section_item coach_card" v-if="selectedPlaybook.display_coach">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`">{{ selectedPlaybook.coach_headline }}</h3>
                                    <div class="user_box">
                                        <img :src="selectedPlaybook.coach_image ? selectedPlaybook.coach_image : require('@/assets/images/customer.svg')" alt="">
                                        <div class="user_info">
                                            <h3>{{ selectedPlaybook.coach_name }}</h3>
                                            <h5>{{ selectedPlaybook.coach_title }}</h5>
                                        </div>
                                    </div>
                                    <p v-html="selectedPlaybook.coach_bio"></p>
                                </div>
                                <div class="section_item action_sec" v-if="selectedPlaybook.recipient_ask.display == 1 || selectedPlaybook.recipient_complete.display == 1 || selectedPlaybook.recipient_complete.display == 1 || customButtons.filter(b => b.show_button == 1).length">
                                    <h3 class="mb-4" :style="`color: ${selectedPlaybook.title_text_color};`">Additional Actions</h3>
                                    <ul class="button_list">
                                        <template v-for="(button, b) in selectedPlaybook.button_ordering" :key="b">
                                            <li v-if="button == 'ask' && selectedPlaybook.recipient_ask.display == 1">
                                                <button type="button" :style="`background-color:${ selectedPlaybook.recipient_ask.button_color }; color:${ selectedPlaybook.recipient_ask.button_txt_color };`">
                                                    <i class="fas fa-plus"></i>{{ selectedPlaybook.recipient_ask.button_text }}
                                                </button>
                                            </li>
                                            <li v-else-if="button == 'understand' && selectedPlaybook.recipient_understand.display == 1">
                                                <button type="button" :style="`background-color:${ selectedPlaybook.recipient_understand.button_color }; color:${ selectedPlaybook.recipient_understand.button_txt_color };`">
                                                    <i class="fas fa-plus"></i>{{ selectedPlaybook.recipient_understand.button_text }}
                                                </button>
                                            </li>
                                            <li v-else-if="button == 'complete' && selectedPlaybook.recipient_complete.display == 1">
                                                <button type="button" :style="`background-color:${ selectedPlaybook.recipient_complete.button_color }; color:${ selectedPlaybook.recipient_complete.button_txt_color };`">
                                                    <i class="fas fa-plus"></i>{{ selectedPlaybook.recipient_complete.button_text }}
                                                </button>
                                            </li>
                                        </template>
                                        <template v-for="(button, b) of customButtons" :key="b">
                                            <li v-if="button.show_button">
                                                <button type="button" :style="`background-color:${ button.bg_color }; color:${ button.text_color };`">
                                                    {{ button.name }}
                                                </button>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <!-- <div class="section_item mb-5 border-item downloads" v-if="form.is_download">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`">Downloads</h3>
                                    <ul class="preview_section download_list">
                                        <li v-for="(download, d) of stepDownloads" :key="d">
                                            <h5 v-if="['xlsx', 'xlsm', 'xls'].includes(download.ext)">
                                                <i class="far fa-file-excel mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <h5 v-else-if="['jpg', 'jpeg', 'png', 'pneg', 'gif', 'tif', 'bmp'].includes(download.ext)">
                                                <i class="far fa-file-image mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <h5 v-else-if="['pdf'].includes(download.ext)">
                                                <i class="far fa-file-pdf mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <h5 v-else-if="['mp4', 'mkv', 'wmv'].includes(download.ext)">
                                                <i class="far fa-file-video mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <h5 v-else-if="['docx', 'doc', 'docm'].includes(download.ext)">
                                                <i class="far fa-file-word mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <h5 v-else-if="['pptx', 'pptm', 'potx', 'potm', 'pot', 'ppsx'].includes(download.ext)">
                                                <i class="far fa-file-powerpoint mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <h5 v-else-if="['mp3', 'pcm', 'wav', 'aac','ogg', 'wma'].includes(download.ext)">
                                                <i class="far fa-file-audio mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <h5 v-else>
                                                <i class="far fa-file mr-1"></i> {{ download.filename }}
                                            </h5>
                                            <span>
                                                <i class="fas fa-cloud-download-alt pointer"></i>
                                            </span>
                                        </li>
                                    </ul>
                                </div> -->
                            </div>
                            <footer class="dashboard_footer" v-show="selectedPlaybook.display_footer" :style="`background: ${selectedPlaybook.footer_bgcolor};`">
                                <ul>
                                    <li class="pointer" v-if="selectedPlaybook.has_facebook"><a :href="selectedPlaybook.footer_facebook" :style="`border-color: ${selectedPlaybook.footer_textcolor}; color: ${selectedPlaybook.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                    <li class="pointer" v-if="selectedPlaybook.has_twitter"><a :href="selectedPlaybook.footer_twitter" :style="`border-color: ${selectedPlaybook.footer_textcolor}; color: ${selectedPlaybook.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                    <li class="pointer" v-if="selectedPlaybook.has_instagram"><a :href="selectedPlaybook.footer_instagram" :style="`border-color: ${selectedPlaybook.footer_textcolor}; color: ${selectedPlaybook.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                    <li class="pointer" v-if="selectedPlaybook.has_linkedin"><a :href="selectedPlaybook.footer_linkedin" :style="`border-color: ${selectedPlaybook.footer_textcolor}; color: ${selectedPlaybook.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                                    <li class="pointer" v-if="selectedPlaybook.has_youtube"><a :href="selectedPlaybook.footer_youtube" :style="`border-color: ${selectedPlaybook.footer_textcolor}; color: ${selectedPlaybook.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                                <h4 :style="`color: ${selectedPlaybook.footer_textcolor};`">© {{ selectedPlaybook.footer_company }}</h4>
                                <p>
                                    <a href="javascript:void(0);" :style="`color: ${selectedPlaybook.footer_textcolor};`">Terms</a>
                                    <a href="javascript:void(0);" :style="`color: ${selectedPlaybook.footer_textcolor};`">Privacy</a>
                                    <span v-html="selectedPlaybook.white_label"></span>
                                </p>
                            </footer>
                        </div>
                    </div>
                    <!-- Instructor & Step listing -->
                    <div class="slide_box_container" :style="`pointer-events:${instructorDetails || steplistDetails || offerDetails ? 'all' : 'none'}`">
                        <div class="slide_box" :class="{'active' : offerDetails}">
                            <div class="box_container">
                                <button class="close_btn" @click="offerDetails = false;"><i class="fas fa-times"></i></button>
                                <div class="product_card">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`" v-if="selectedPlaybook.display_offer_headline && selectedPlaybook.offer_headline">{{ selectedPlaybook.offer_headline }}</h3>
                                    <img :src="selectedPlaybook.offer_image ? selectedPlaybook.offer_image : require('@/assets/images/thumb/default-step-cover.svg')" alt="product-image">
                                </div>
                            </div>
                        </div>
                        <div class="slide_box" :class="{'active' : instructorDetails}">
                            <div class="box_container">
                                <button class="close_btn" @click="instructorDetails = false;"><i class="fas fa-times"></i></button>
                                <div class="instructor">
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`">{{ selectedPlaybook.coach_headline }}</h3>
                                    <div class="user_box">
                                        <img :src="selectedPlaybook.coach_image ? selectedPlaybook.coach_image : require('@/assets/images/customer.svg')" alt="">
                                        <div class="user_info">
                                            <h3>{{ selectedPlaybook.coach_name }}</h3>
                                            <h5>{{ selectedPlaybook.coach_title }}</h5>
                                        </div>
                                    </div>
                                    <p v-html="selectedPlaybook.coach_bio"></p>
                                </div>
                            </div>
                        </div>
                        <div class="slide_box" :class="{'active' : steplistDetails}">
                            <div class="box_container">
                                <button class="close_btn" @click="steplistDetails = false;"><i class="fas fa-times"></i></button>
                                <div class="step_listing border-0 p-0">
                                    <div class="progress_sec" v-if="selectedPlaybook.display_progressbar">
                                        <h3 :style="`color: ${selectedPlaybook.title_text_color};`">0 of {{playbookSteps.length}} Steps Completed</h3>
                                        <div class="status mb-2"><span style="width:50%;"></span></div>
                                    </div>
                                    <h3 :style="`color: ${selectedPlaybook.title_text_color};`" class="mb-4">Course Content</h3>
                                    <ul>
                                        <li class="step_card" v-for="(step, s) in playbookSteps" :key="s">
                                            <template v-if="!step.is_draft">
                                                <div class="items">
                                                    <div class="thumb" v-if="selectedPlaybook.has_step_thumb">
                                                        <img :src="step.thumbnail ? step.thumbnail : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                                                    </div>
                                                    <div class="step_info">
                                                        <h5>{{ step.title }}</h5>
                                                        <p>{{ step.thumb_desc && step.thumb_desc.length > 60 ? step.thumb_desc.substr(0, 60) + '...' : step.thumb_desc }}</p>
                                                    </div>
                                                </div>
                                                <div class="step_lock" :style="`background: ${selectedPlaybook.step_locked_bg}; color: ${selectedPlaybook.step_locked_text_color};`" v-if="selectedPlaybook.must_be_completed && s > unlockedStep"><i class="fas fa-lock" :style="`border-color: ${selectedPlaybook.step_locked_text_color};`"></i></div>
                                            </template>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-show="tab == 'drip'">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_drip" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_drip" v-model="previewMsg" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="dripPreview = 'email'" :class="{'active': dripPreview === 'email'}" v-if="(dripTab === 'step' && form.drip_notification_method === 3) || (dripTab === 'tab' && tabDrip.sending_method === 3)">E-mail</li>
                    <li @click="dripPreview = 'sms'" :class="{'active': dripPreview === 'sms'}" v-if="(dripTab === 'step' && form.drip_notification_method === 3) || (dripTab === 'tab' && tabDrip.sending_method === 3)">SMS</li>
                    <li @click="dripPreview = 'email'" class="active" v-if="(dripTab === 'step' && form.drip_notification_method === 1) || (dripTab === 'tab' && tabDrip.sending_method === 1)">E-mail</li>
                    <li @click="dripPreview = 'sms'" class="active" v-if="(dripTab === 'step' && form.drip_notification_method === 2) || (dripTab === 'tab' && tabDrip.sending_method === 2)">SMS</li>
                </ul>
                <div class="cell" v-if="dripTab == 'step'">
                    <div class="content_area" v-if="(form.drip_notification_method === 3 && dripPreview === 'email') || form.drip_notification_method == 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(stepDripEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="stepDripEmail.show_email_logo">
                                <img :src="stepDripEmail.email_logo ? stepDripEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(stepDripEmail.email, stepDripEmail.email_button_bg_color, stepDripEmail.email_button_text_color)"></div>
                            <div v-html="generateSignature(stepDripEmail.is_signature, stepDripEmail.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="(form.drip_notification_method === 3 && dripPreview === 'sms') || form.drip_notification_method === 2">
                        <div class="sms_preview">
                            <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(form.drip_notification_sms)"></div>
                        </div>
                    </div>
                </div>
                <div class="cell" v-if="dripTab == 'tab'">
                    <div class="content_area" v-if="(tabDrip.sending_method === 3 && dripPreview === 'email') || tabDrip.sending_method === 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(tabDripEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="tabDripEmail.show_email_logo">
                                <img :src="tabDripEmail.email_logo ? tabDripEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(tabDripEmail.email, tabDripEmail.email_button_bg_color, tabDripEmail.email_button_text_color)"></div>
                            <div v-html="generateSignature(tabDripEmail.is_signature, tabDripEmail.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mb-2">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="(tabDrip.sending_method === 3 && dripPreview === 'sms') || tabDrip.sending_method === 2">
                        <div class="sms_preview">
                            <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(tabDrip.sms_content)"></div>
                        </div>
                    </div>
                </div>
            </div>
            <add-step-component v-model="createStep" :playbook="selectedPlaybook" :handle-step-create="handleStepCreate" />
            <step-preview v-model="stepPreview" :step="selectedStep" />
            <drip-preview v-model="previewMsg" :drip-tab="dripTab" :drip-preview="dripPreview" :step-drip="stepDripEmail" :tab-drip="tabDrip" :tab-drip-email="tabDripEmail" :form="form" />
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import SwiperCore, { Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapGetters, mapActions } from 'vuex'

    const ContentThumbnail = defineAsyncComponent(() => import('@/components/ContentThumbnail'))
    const DripComponent = defineAsyncComponent(() => import('@/pages/playbook/components/Drip'))
    const AddStepComponent = defineAsyncComponent(() => import('@/pages/playbook/components/AddStep'))
    const StepPreview = defineAsyncComponent(() => import('@/pages/playbook/components/StepPreview'))
    const DripPreview = defineAsyncComponent(() => import('@/pages/playbook/components/DripPreview'))

    import moment from 'moment-timezone'
    import Draggable from 'vuedraggable'
    import Swal from 'sweetalert2'
    import Dropzone from 'dropzone'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'
    import VideoParser from '@/utils/VideoParser'

    import 'dropzone/dist/dropzone.css'
    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation])

    export default {
        name: 'Playbook Offer Headline',

        data () {
            return {
                form: {
                    step_url: '',
                    url_active: 0,
                    title: '',
                    thumb_desc: '',
                    is_smart_step: 0,
                    smart_step_title: '',
                    thumbnail: '',
                    banner: '',
                    show_main_video: 0,
                    has_additional_video: 0,
                    description: '',
                    is_tabs: 0,
                    drip_schedule: 0,
                    drip_notification: 0,
                    drip_notification_method: 1,
                    has_tabs_drip: 0,
                    has_tabs_drip_notification: 0,
                    is_download: 0,
                    apply_to_all: 0,
                    display_thumb: 1,
                    display_banner: 1,
                },
                accountDrop: false,
                addTab: false,
                preview: true,
                imageTab: 'thumb',
                createStep: false,
                playbookSteps: [],
                selectedStep: {},
                tab: 'setting',
                dripTab: 'step',
                stepContentResetWatcher: 0,
                tabContentResetWatcher: 0,
                selectedTab: {},
                selectedTabDrip: {},
                activeTab: {id: 0},
                activeTabPreview: {id: 0},
                stepTabs: [],
                stepMainThumbnail: {
                    type: 'url',
                    url: '',
                    embed: '',
                    image: '',
                    title: '',
                },
                stepAdditionalThumbnail: {
                    type: 'url',
                    url: '',
                    embed: '',
                    image: '',
                    title: '',
                },
                stepDripForm: {
                    days: 0,
                    datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    timezone: null,
                    drip_method: 1,
                },
                tabDripForm: [{
                    id: 0,
                    days: 0,
                    datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    timezone: null,
                    drip_method: 1,
                }],
                stepDripEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                tabDrip: {
                    sending_method: 1,
                },
                tabDripEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                drip_notification_sms_media: [],
                tab_drip_sms_media: [],
                dropzone: null,
                loaded: false,
                stepAdditionalThumbnailReload: false,
                tabForm: {},
                activeStepTab: 0,
                tabAutoSave: true,
                stepPreview: false,
                closeWindow: true,
                isDownloadEdited: false,
                filenameValidation: false,
                selectedDownload: {},
                stepDownloads: [],
                unlockedStep: 0,
                isTyping: false,
                dripPreview: 'email',
                whiteLabel: {},
                previewMsg: false,
                offerDetails: false,
                instructorDetails: false,
                steplistDetails: false,
                stepLoader: false,
                fileSizeValidation: false,
                toggleAll: 1,
                general_setup: false,
            };
        },

        props: {
            modelValue: Boolean,
            step: {
                type: Object,
                default: () => {}
            }
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            Draggable,
            ContentThumbnail,
            DripComponent,
            AddStepComponent,
            StepPreview,
            DripPreview,
            // Swiper,
            // SwiperSlide,
        },

        watch: {
            modelValue (modelValue) {
                if (modelValue) {
                    const vm = this;

                    vm.stepLoader = true;

                    setTimeout(function () {
                        vm.initDropzone();
                        vm.stepLoader = false;
                    }, 1000);

                    setTimeout(function () {
                        vm.loaded = true;
                        vm.stepContentResetWatcher += 1;
                        vm.tabContentResetWatcher += 1;
                    }, 2000);
                }
            },

            selectedPlaybook () {
                const vm = this;

                if (vm.selectedStep.id) {
                    vm.resetForm();
                }
            },

            step (step) {
                const vm = this;

                vm.selectedStep = step;
                vm.resetForm();
            },

            selectedStep (step) {
                const vm = this;

                vm.resetForm();
                vm.getStepDownloads(step.id);

                setTimeout(function () {
                    vm.initDropzone();
                }, 1000);
            },

            'selectedPlaybookSteps.additional_tabs'(tabs) {
                    const vm = this;

                    // vm.stepTabs       = tabs ? JSON.parse(JSON.stringify(tabs)) : [];
            },

            selectedTab: {
                handler (oldTab, newTab) {
                    if (newTab.content == oldTab.content) {
                        const vm = this;

                        vm.isTyping = true;

                        if (oldTab.id && (oldTab.id != newTab.id) || !oldTab.id) {
                            vm.isTyping = false;
                            setTimeout(function () {
                                vm.tabContentResetWatcher   += 1;
                            }, 100);
                        }

                        if (oldTab.id && (oldTab.id != newTab.id) && vm.tabAutoSave) {
                            vm.handleUpdateTab(oldTab);
                        }

                        setTimeout(function () {
                            vm.isTyping = false;
                        }, 3000);
                    }
                },
                deep: true,
            },

            isTyping (isTyping) {
                if (!isTyping) {
                    const vm = this;

                    vm.handleUpdateTab(vm.selectedTab);
                }
            },

            tab (tab, oldTab) {
                const vm = this;

                if (oldTab == 'main') {
                    vm.handleUpdateTab(vm.selectedTab);
                }
            },

            selectedPlaybookSteps (steps) {
                const vm = this;

                vm.playbookSteps  = JSON.parse(JSON.stringify(steps));

                setTimeout(function () {
                    const index = vm.playbookSteps.findIndex(({id}) => id == vm.selectedStep.id);
                    vm.stepTabs = vm.playbookSteps[index] && vm.playbookSteps[index].additional_tabs ? vm.playbookSteps[index].additional_tabs : [];
                }, 10);
            },

            stepPreview (val) {
                const vm = this;

                if (val) {
                    const button = document.getElementById('save-step-button');

                    if (button) {
                        vm.closeWindow = false;
                        button.click();
                    }
                }

                vm.toggleGistChatBox();
            },

            stepDownloadsArray (stepDownloads) {
                const vm = this;

                vm.stepDownloads = JSON.parse(JSON.stringify(stepDownloads));
            },
        },

        computed: {
            ...mapState({
                user: state => state.authModule.user,
                authToken: state => state.authModule.authToken,
                membership: state => state.authModule.membership,
                selectedPlaybook: state => state.playbookModule.selectedPlaybook,
                selectedPlaybookSteps: state => state.playbookModule.playbookSteps,
                playbookStepSaveLoader: state => state.playbookModule.playbookStepSaveLoader,
                playbookStepTabSaveLoader: state => state.playbookModule.playbookStepTabSaveLoader,
                stepDownloadsArray: state => state.playbookModule.stepDownloads,
                customButtons: state => state.playbookModule.customButtons,
                playbookStepDuplicateLoader: state => state.playbookModule.playbookStepDuplicateLoader,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();

            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};

            window.addEventListener("resize", vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted () {
            const vm = this;
            window.removeEventListener("resize", vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                updatePlaybookStep: 'playbookModule/updatePlaybookStep',
                sortPlaybookStep: 'playbookModule/sortPlaybookStep',
                addNewTab: 'playbookModule/addNewTab',
                getPlaybookStep: 'playbookModule/getPlaybookStep',
                updateStepTab: 'playbookModule/updateStepTab',
                sortPlaybookStepTabs: 'playbookModule/sortPlaybookStepTabs',
                getStepDownloads: 'playbookModule/getStepDownloads',
                deleteStepDownload: 'playbookModule/deleteStepDownload',
                deleteTab: 'playbookModule/deleteTab',
                deleteStep: 'playbookModule/deleteStep',
                renamePlaybookDownloadFile: 'playbookModule/renamePlaybookDownloadFile',
                duplicatePlaybookStep: 'playbookModule/duplicatePlaybookStep',
                getPlaybookStepContent: 'playbookModule/getPlaybookStepContent',
            }),

            closeModal () {
                const vm = this;

                vm.loaded = false;
                vm.$emit('update:modelValue', false);
                vm.fileSizeValidation = false;
            },

            resetForm () {
                const vm = this;

                if (!vm.selectedStep.description) {
                    vm.selectedStep.description = '<p></p>';
                }

                vm.form = {
                    step_id: vm.selectedStep.id,
                    step_url: vm.selectedStep.step_url ? vm.selectedStep.step_url : '',
                    url_active: vm.selectedStep.url_active ? vm.selectedStep.url_active : 0,
                    title: vm.selectedStep.title ? vm.selectedStep.title : '',
                    thumb_desc: vm.selectedStep.thumb_desc ? vm.selectedStep.thumb_desc : '',
                    is_smart_step: vm.selectedStep.is_smart_step ? vm.selectedStep.is_smart_step : 0,
                    smart_step_title: vm.selectedStep.smart_step_title ? vm.selectedStep.smart_step_title : '',
                    thumbnail: vm.selectedStep.thumbnail ? vm.selectedStep.thumbnail : '',
                    banner: vm.selectedStep.banner ? vm.selectedStep.banner : '',
                    show_main_video: vm.selectedStep.show_main_video ? vm.selectedStep.show_main_video : 0,
                    has_additional_video: vm.selectedStep.has_additional_video ? vm.selectedStep.has_additional_video : 0,
                    show_additional_video: vm.selectedStep.show_additional_video ? vm.selectedStep.show_additional_video : 0,
                    description: vm.selectedStep.description ? vm.selectedStep.description : '<p></p>',
                    is_tabs: vm.selectedStep.is_tabs ? vm.selectedStep.is_tabs : 0,
                    drip_schedule: vm.selectedStep.drip_schedule ? vm.selectedStep.drip_schedule : 0,
                    drip_notification: vm.selectedStep.drip_notification ? vm.selectedStep.drip_notification : 0,
                    drip_notification_method: vm.selectedStep.drip_notification_method ? vm.selectedStep.drip_notification_method : 1,
                    has_tabs_drip: vm.selectedStep.has_tabs_drip ? vm.selectedStep.has_tabs_drip : 0,
                    has_tabs_drip_notification: vm.selectedStep.has_tabs_drip_notification ? vm.selectedStep.has_tabs_drip_notification : 0,
                    is_download: vm.selectedStep.is_download ? vm.selectedStep.is_download : 0,
                    drip_notification_sms: vm.selectedStep.drip_notification_sms ? vm.selectedStep.drip_notification_sms : '',
                    apply_to_all: vm.selectedStep.apply_to_all ? vm.selectedStep.apply_to_all : 0,
                    display_thumb: vm.selectedStep.display_thumb ? vm.selectedStep.display_thumb : 0,
                    display_banner: vm.selectedStep.display_banner ? vm.selectedStep.display_banner : 0,
                };

                vm.tab            = 'setting';
                vm.playbookSteps  = JSON.parse(JSON.stringify(vm.selectedPlaybookSteps));
                vm.stepTabs       = vm.selectedStep.additional_tabs ? JSON.parse(JSON.stringify(vm.selectedStep.additional_tabs)) : [];
                vm.selectedTab    = vm.selectedStep.additional_tabs && vm.selectedStep.additional_tabs[0] ? vm.selectedStep.additional_tabs[0] : {};
                vm.activeTab      = vm.selectedStep.additional_tabs && vm.selectedStep.additional_tabs[0] ? JSON.parse(JSON.stringify(vm.selectedStep.additional_tabs[0])) : {};
                vm.tabDrip        = vm.selectedStep.tabs_notification && vm.selectedStep.tabs_notification != 'null' ? JSON.parse(vm.selectedStep.tabs_notification) : { sending_method: 1 };
                vm.tabDripForm    = [];
                vm.activeTabPreview = vm.selectedStep.additional_tabs && vm.selectedStep.additional_tabs[0] ? vm.selectedStep.additional_tabs[0] : {};

                setTimeout(function () {
                    const selector = document.querySelector('.tab_sec.mb-3.mt-3 .move.active');

                    if (selector) {
                        selector.click();
                    }
                }, 1500);

                if (vm.selectedStep.additional_video) {
                    vm.stepAdditionalThumbnail = vm.selectedStep.additional_video;

                    setTimeout(function () {
                        vm.stepAdditionalThumbnailReload = true;
                    }, 100);
                }

                vm.stepTabs.forEach((tab, index) => {
                    const drip = JSON.parse(tab.drip_delay);

                    if (index == 0) {
                        vm.selectedTabDrip = tab;
                    }

                    vm.tabDripForm.push({
                        id: tab.id,
                        days: drip && drip.days ? drip.days : 0,
                        datetime: drip && drip.datetime ? moment.tz(drip.datetime, drip.timezone).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
                        timezone: drip && drip.timezone ? drip.timezone : null,
                        drip_method: drip && drip.drip_method ? drip.drip_method : 1,
                    });
                });

                vm.stepDripEmail = {
                    defaultHandler: true,
                    subject: vm.selectedStep.drip_notification_email_subject ? vm.selectedStep.drip_notification_email_subject : '',
                    email: vm.selectedStep.drip_notification_email_message ? vm.selectedStep.drip_notification_email_message : '',
                    is_email_logo: vm.selectedStep.show_email_logo ? vm.selectedStep.show_email_logo : 0,
                    is_signature: vm.selectedStep.drip_notification_has_signature ? vm.selectedStep.drip_notification_has_signature : 0,
                    signature_id: vm.selectedStep.drip_notification_signature_id ? vm.selectedStep.drip_notification_signature_id : 0,
                    show_email_logo: vm.selectedStep.show_email_logo ? vm.selectedStep.show_email_logo : 0,
                    email_logo: vm.selectedStep.email_logo ? vm.selectedStep.email_logo : '',
                    module_id: vm.selectedPlaybook.id,
                };

                vm.tabDripEmail = {
                    defaultHandler: true,
                    subject: vm.tabDrip && vm.tabDrip.email_subject ? vm.tabDrip.email_subject : '',
                    email: vm.tabDrip && vm.tabDrip.email_content ? vm.tabDrip.email_content : '',
                    is_email_logo: vm.tabDrip && vm.tabDrip.show_email_logo ? vm.tabDrip.show_email_logo : 0,
                    is_signature: vm.tabDrip && vm.tabDrip.has_signature ? 1 : 0,
                    signature_id: vm.tabDrip && vm.tabDrip.signature_id ? vm.tabDrip.signature_id : 0,
                    show_email_logo: vm.tabDrip && vm.tabDrip.show_email_logo ? vm.tabDrip.show_email_logo : 0,
                    email_logo: vm.tabDrip && vm.tabDrip.email_logo ? vm.tabDrip.email_logo : '',
                    module_id: vm.selectedPlaybook.id,
                };

                vm.stepDripForm = {
                    days: vm.selectedStep.drip_delay,
                    datetime: vm.selectedStep.step_drip_method == 2 && vm.selectedStep.step_drip_array ? vm.selectedStep.step_drip_array.datetime : moment().format('YYYY-MM-DD HH:mm:ss'),
                    timezone: vm.selectedStep.step_drip_array ? vm.selectedStep.step_drip_array.timezone : null,
                    drip_method: vm.selectedStep.step_drip_method,
                };

                if (vm.tabDrip && vm.tabDrip.email_subject == undefined) {
                    vm.handleTabDripDefaultEmail();
                    vm.handleTabDripDefaultSms();
                }

                if (vm.tabDrip && vm.tabDrip.sms_content == undefined) {
                    vm.handleTabDripDefaultSms();
                }

                setTimeout(function () {
                    vm.stepContentResetWatcher += 1;
                    vm.tabContentResetWatcher += 1;

                    vm.stepMainThumbnail = {
                        type: vm.selectedStep.video_option == 1 ? 'embed' : (vm.selectedStep.video_option == 2 ? 'url' : 'image'),
                        url: vm.selectedStep.url,
                        embed: vm.selectedStep.embed,
                        image: vm.selectedStep.image,
                        title: vm.selectedStep.thumb_title,
                    };
                }, 1100);
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = vm.form;

                params.setFieldError                    = setFieldError;
                params.drip_notification_email_subject  = vm.stepDripEmail.subject;
                params.drip_notification_email_message  = vm.stepDripEmail.email;
                params.show_email_logo                  = vm.stepDripEmail.is_email_logo;
                params.drip_notification_has_signature  = vm.stepDripEmail.is_signature;
                params.drip_notification_signature_id   = vm.stepDripEmail.signature_id;
                params.show_email_logo                  = vm.stepDripEmail.show_email_logo;
                params.email_logo                       = vm.stepDripEmail.email_logo;
                params.additional_video                 = vm.stepAdditionalThumbnail;
                params.video_option                     = vm.stepMainThumbnail.type == 'embed' ? 1 : (vm.stepMainThumbnail.type == 'url' ? 2 : 3),
                params.url                              = vm.stepMainThumbnail.url,
                params.embed                            = vm.stepMainThumbnail.embed,
                params.image                            = vm.stepMainThumbnail.image,
                params.thumb_title                      = vm.stepMainThumbnail.title,
                params.drip_delay                       = vm.stepDripForm.days,
                params.step_drip_method                 = vm.stepDripForm.drip_method,
                params.step_drip                        = { datetime: moment(vm.stepDripForm.datetime).format(), timezone: vm.stepDripForm.timezone },
                params.tabs_notification                = {
                                                              email_subject: vm.tabDripEmail.subject,
                                                              email_content: vm.tabDripEmail.email,
                                                              has_signature: vm.tabDripEmail.is_signature,
                                                              signature_id: vm.tabDripEmail.signature_id,
                                                              show_email_logo: vm.tabDripEmail.show_email_logo,
                                                              email_logo: vm.tabDripEmail.email_logo,
                                                          };

                let tabDrips = JSON.parse(JSON.stringify(vm.tabDripForm));

                params.tabs_drips  = [];

                tabDrips.forEach((tabDrip, t) => {
                    let time = moment(tabDrip.datetime).format('YYYY-MM-DD kk:mm:ss');
                    tabDrip['datetime'] = moment.tz(time, tabDrip.timezone).format();
                    params.tabs_drips.push(tabDrip);
                });

                vm.handleUpdateTab(vm.selectedTab);

                setTimeout(function () {
                    vm.updatePlaybookStep(params).then((result) => {
                        if (result) {
                            vm.getPlaybookStep(vm.selectedPlaybook.id);

                            if (vm.closeWindow) {
                                // vm.closeModal();
                            }

                            vm.closeWindow = true;
                        }
                    });
                }, 10);
            },

            handleStepsSort (e) {
                const vm  = this;
                const ids = [];

                vm.playbookSteps.forEach((step) => {
                    ids.push(step.id);
                });

                vm.sortPlaybookStep({ ids, playbook_id: vm.selectedPlaybook.id });
            },

            stepSwitch (step) {
                const vm = this;

                vm.stepLoader = true;

                if (step.id) {
                    vm.getPlaybookStepContent({ playbook_id: vm.selectedPlaybook.id, step_id: step.id }).then((resp) => {
                        if (resp) {
                            vm.selectedStep = resp;
                        } else {
                            vm.selectedStep = step;
                        }

                        vm.resetForm();
                        vm.stepLoader = false;
                    });
                } else {
                    vm.selectedStep = step;

                    setTimeout(function () {
                        vm.stepLoader = false;
                    }, 1000);
                }
            },

            handleTabSort (e) {
                const vm  = this;
                const ids = [];

                vm.stepTabs.forEach((tab) => {
                    ids.push(tab.id);
                });

                vm.sortPlaybookStepTabs({ ids, playbook_id: vm.selectedPlaybook.id, step_id: vm.selectedStep.id });
            },

            handleStepDripDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{first_name}},</p>';
                    email += '<p>New content is available for you in your {{playbook_name}} training</p>';
                    email += '<p>Click here to access {{step_title}}</p>';
                    email += '<p>{{auto_login_link}}</p>';
                    email += '<p>Cheers!</p>';
                    email += '<p>{{company_name}}</p>';

                vm.stepDripEmail.subject  = '{{first_name}} New Content Available!';
                vm.stepDripEmail.email    = email;
            },

            handleStepDripDefaultSms () {
                const vm = this;

                vm.form.drip_notification_sms = 'Hi {{first_name}}!\r\nNew content has been released in your {{playbook_name}} playbook.\r\nAuto-login: {{auto_login_sms_link}}';
            },

            handleTabDripDefaultEmail () {
                const vm = this;
                let email = '<p>Hi {{first_name}},</p>';
                    email += '<p>New content is available for you in your {{playbook_name}} training</p>';
                    email += '<p>Step Title: {{step_title}} <br />';
                    email += 'Tab Title: {{tab_title}}</p>';
                    email += '<p>Click here to access</p>';
                    email += '<p>{{auto_login_link}}</p>';
                    email += '<p>Cheers!</p>';
                    email += '<p>{{company_name}}</p>';

                vm.tabDripEmail.subject = '{{first_name}} New Content Available!';
                vm.tabDripEmail.email   = email;
            },

            handleTabDripDefaultSms () {
                const vm = this;

                vm.tabDrip.sms_content = 'Hi {{first_name}}!\r\nNew content has been released in your {{playbook_name}} playbook.\r\nStep Title: {{step_title}} \r\nTab Title: {{tab_title}}\r\nAuto-login: {{auto_login_sms_link}}';
            },

            initDropzone () {
                const vm = this;

                const hasDropzoneEl = document.getElementById(`step-download-dropzone`);

                if (hasDropzoneEl) {
                    if (vm.dropzone) {
                        vm.dropzone.destroy();
                    }

                    vm.dropzone = new Dropzone(`#step-download-dropzone`, {
                        url: process.env.VUE_APP_API_URL+`/api/v3/playbook/step/${vm.selectedStep.id}/download`,
                        uploadMultiple: true,
                        paramName: 'files',
                        headers: {
                            'Authorization': 'Bearer '+vm.authToken.access_token,
                            'accessed-location-id': vm.authToken.accessed_location_id,
                            'company-admin-id': vm.authToken.company_admin_id,
                        },
                        success: function (file, resp) {
                            vm.getStepDownloads(vm.selectedStep.id).then(() => {
                                if (file.previewElement) {
                                    file.previewElement.parentNode.removeChild(file.previewElement);
                                    const el = document.getElementById(`#step-download-dropzone`);
                                    el.classList.remove('dz-started');
                                    el.classList.remove('dz-max-files-reached');
                                }
                            });
                        },
                        error: function (file, error) {
                           vm.fileSizeValidation = error.errors["files.0"];
                        },
                    });
                }
            },

            handleAddTab (form, { setFieldError }) {
                const vm = this;
                const params = vm.tabForm;
                params.playbook_id = vm.selectedPlaybook.id;
                params.step_id = vm.selectedStep.id;

                vm.addNewTab(params).then((result) => {
                    if (result) {
                        vm.getPlaybookStep(vm.selectedPlaybook.id);
                        vm.stepTabs.push(result);
                        vm.addTab = false;
                        vm.tabDripForm.push({
                            id: result.id,
                            days: 0,
                            datetime: moment().format('YYYY-MM-DD HH:mm:ss'),
                            timezone: vm.user.timezone,
                            drip_method: 1,
                        });

                        if (!vm.selectedTab.id) {
                            vm.selectedTab = result;
                        }

                        if (!vm.selectedTabDrip.id) {
                            vm.selectedTabDrip = vm.stepTabs[0];
                        }

                        vm.tabForm = { title: '' };
                    }
                });
            },

            handleUpdateTab (tab) {
                const vm = this;

                if (tab.id) {
                    vm.updateStepTab(tab).then((result) => {
                        if (result) {
                            const index = vm.stepTabs.findIndex(tab => { return tab.id === result.id;});

                            if (index >= 0) {
                                vm.stepTabs[index] = result;
                            }

                            if (tab.id == vm.activeTabPreview.id) {
                                vm.activeTabPreview = tab;
                            }

                            vm.getPlaybookStep(vm.selectedPlaybook.id);
                        }
                    });
                }
            },

            handleDownloadRemove (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this file', 'Delete')
                      option.preConfirm = function() { return vm.deleteStepDownload({ id, step_id: vm.selectedStep.id }); };

                Swal.fire(option);
            },

            handleStepCreate (step, edit = 0) {
                const vm = this;

                if (edit) {
                    setTimeout(function () {
                        vm.selectedStep = vm.playbookSteps.filter((s) => s.id == step.id)[0];
                        vm.tab = 'setting';
                    }, 100);
                }
            },

            faqAcc (e) {
                let el = e.currentTarget;
                let allEl = document.querySelectorAll('.faq_list li');

                if (el.classList.contains('show')) {
                    el.classList.remove('show');
                } else {
                    for(let i = 0; i < allEl.length; i++){
                        allEl[i].classList.remove('show');
                    }

                    el.classList.add('show');
                }
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            resetTabForm(tab) {
                const vm = this;

                vm.selectedTab = {
                    id: tab.id,
                    title: tab.title ? tab.title : '',
                    bg_color: tab.bg_color ? tab.bg_color : '#D4D4D4',
                    text_color: tab.text_color ? tab.text_color : '#2C3E50',
                    thumbnail: tab.thumbnail ? tab.thumbnail : { type: 'url', url: '', embed: '', image: '', title: '' },
                    content: tab.content ? tab.content : '',
                    display_tab: tab.display_tab ? tab.display_tab : 0,
                };

                vm.tabContentResetWatcher   += 1;
            },

            handleTabDelete (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this tab', 'Delete')
                      option.preConfirm = function() {
                                                          return vm.deleteTab(id).then((result) => {
                                                              vm.getPlaybookStep(vm.selectedPlaybook.id);

                                                              const tabIndex = vm.stepTabs.findIndex(tab => { return tab.id === id});

                                                              if (vm.stepTabs[(parseInt(tabIndex) + 1)]) {
                                                                  vm.selectedTab      = vm.stepTabs[(parseInt(tabIndex) + 1)];
                                                                  vm.activeTab        = JSON.parse(JSON.stringify(vm.stepTabs[(parseInt(tabIndex) + 1)]));
                                                                  vm.selectedTabDrip  = vm.stepTabs[(parseInt(tabIndex) + 1)];
                                                              } else if (vm.stepTabs[(parseInt(tabIndex) - 1)]) {
                                                                  vm.selectedTab      = vm.stepTabs[(parseInt(tabIndex) - 1)];
                                                                  vm.activeTab        = JSON.parse(JSON.stringify(vm.stepTabs[(parseInt(tabIndex) - 1)]));
                                                                  vm.selectedTabDrip  = vm.stepTabs[(parseInt(tabIndex) - 1)];
                                                              } else {
                                                                  vm.selectedTab      = {};
                                                                  vm.activeTab        = {};
                                                                  vm.selectedTabDrip  = {};
                                                              }

                                                              delete vm.stepTabs[tabIndex];
                                                              vm.stepTabs = vm.stepTabs.filter((_, index) => vm.stepTabs.hasOwnProperty(index));
                                                              vm.tabAutoSave = false;
                                                          });
                                                      };

                Swal.fire(option);
            },

            handleStepDelete (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', 'This action is permanent. You will not be able to recover this step', 'Delete')
                      option.preConfirm = function() {
                                                          return vm.deleteStep(id).then((result) => {
                                                              vm.getPlaybookStep(vm.selectedPlaybook.id);

                                                              const tabIndex = vm.playbookSteps.findIndex(step => { return step.id === id});

                                                              if (vm.playbookSteps[(parseInt(tabIndex) + 1)]) {
                                                                  vm.selectedStep      = vm.playbookSteps[(parseInt(tabIndex) + 1)];
                                                              } else if (vm.playbookSteps[(parseInt(tabIndex) - 1)]) {
                                                                  vm.selectedStep      = vm.playbookSteps[(parseInt(tabIndex) - 1)];
                                                              } else {
                                                                  vm.closeModal();
                                                              }
                                                          });
                                                      };

                Swal.fire(option);
                vm.fileSizeValidation = false;
            },

            goto (refName) {
                const vm = this;
                let element = vm.$refs[refName];

                setTimeout(function () {
                    if (element && element.offsetTop) {
                        let top = element.offsetTop;
                        document.querySelector('.preview_content .content_area').scrollTo(0, top);
                    }
                }, 100);
            },

            setting (refName) {
                const vm = this;
                let element = vm.$refs[refName];

                if (refName === 'hdrsetting') {
                    vm.tab = 'header';
                } else if(refName === 'cvrsetting') {
                    vm.tab = 'image';
                } else if(refName === 'ttlsetting') {
                    vm.tab = 'setting';
                } else if(refName === 'mainsetting') {
                    vm.tab = 'main';
                }

                setTimeout(function () {
                    if (element && element.offsetTop) {
                        let top = element.offsetTop;
                        document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                    }
                }, 100);
            },

            scrollFunc (e) {
                e.preventDefault();
                e.currentTarget.scrollLeft += e.deltaY;
            },

            handleDownloadRename () {
                const vm = this;

                if (vm.selectedDownload.filename && vm.selectedDownload.filename.trim()) {
                    vm.filenameValidation = false;

                    vm.renamePlaybookDownloadFile(vm.selectedDownload).then((result) => {
                        if (result) {
                            const index = vm.stepDownloads.findIndex((download) => download.id == vm.selectedDownload.id);

                            vm.stepDownloads[index].filename = vm.selectedDownload.filename;
                            vm.selectedDownload = {};
                            vm.isDownloadEdited = false;
                        }
                    });
                } else {
                    vm.filenameValidation = true;
                    Toastr.error('Please enter the file name to rename!');
                }
            },

            handleEditDownload (download) {
                const vm = this;

                vm.selectedDownload = JSON.parse(JSON.stringify(download));
                vm.isDownloadEdited = true;
                vm.filenameValidation = false;
                vm.fileSizeValidation = false;
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },

            confimApplyToAllTabs (e) {
                const vm     = this;
                const button = document.getElementById('save-step-button');

                if (e.target.checked) {
                    const option = Helper.swalConfirmOptions('Are you sure?', 'This will override any preset color combinations you have set on your tabs. This can not be undone. Proceed?', 'Yes', false)

                    Swal.fire(option).then(({isConfirmed}) => {
                        if (isConfirmed && button) {
                            button.click();
                        } else {
                            vm.form.apply_to_all = 0;
                        }
                    });
                } else {
                    if (button) {
                        button.click();
                    }
                }
            },

            handleDuplicatePlaybookStep () {
                const vm = this;

                vm.duplicatePlaybookStep({ id: vm.selectedStep.id }).then((result) => {
                    if (result) {
                        vm.getPlaybookStep(vm.selectedPlaybook.id);
                    }
                });
            },

            copyStepUrl () {
                const vm = this;

                copyText(vm.form.step_url, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                });
            },

            openSlide(type){
                const vm = this;
                if(type == 'offer'){
                    vm.steplistDetails = false;
                    vm.instructorDetails = false;
                    vm.offerDetails = true;
                } else if(type == 'instructor'){
                    vm.offerDetails = false;
                    vm.steplistDetails = false;
                    vm.instructorDetails = true;
                } else if(type == 'menu'){
                    vm.instructorDetails = false;
                    vm.offerDetails = false;
                    vm.steplistDetails = true;

                }
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for(let i = 0; i < allParentElm.length; i++){
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },

            close_gen_setup(){
                const vm = this;
                vm.general_setup = false;
            },

            toggleDetails (ev) {
                let elm = ev.currentTarget;
                let targetElm = elm.closest('.color_container').querySelector('.content_details');

                if (targetElm.classList.contains('show')) {
                    targetElm.classList.remove('show');
                    elm.innerHTML = 'Open';
                } else {
                    targetElm.classList.add('show');
                    elm.innerHTML = 'Close';
                }
            },

            toggleTabDetails(ev, el){
                let elm = ev.currentTarget;
                let targetElm = elm.closest(el).querySelector('.content_details');

                if(targetElm.classList.contains('show')){
                    targetElm.classList.remove('show');
                    elm.innerHTML = 'Open';
                }else{
                    targetElm.classList.add('show');
                    elm.innerHTML = 'Close';
                }
            },
        },
    }
</script>

<style scoped>
    .global_setting .section_header h2 {
        font-size: 20px;
        line-height: 28px;
    }
    .global_setting .section_header h2 span{
        font-size: 11px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 500;
        display: block;
        padding-top: 5px;
    }
    .global_setting .color_container {
        padding: 20px 30px;
        /* border: 1px solid #e9e9e9; */
        background: #fff;
        border-radius: 6px;
        flex: 1;
    }
    .global_setting .color_container .content_details, .global_setting .item_container .content_details{
        display: none;
    }
    .global_setting .color_container .content_details.show, .global_setting .item_container .content_details.show{
        display: block;
    }
    .section_content .section_header .status{
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right: 20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after{
        border: 0;
    }
    .global_setting .tabs_content .section_content .section_header{
        margin: 10px 0;
    }
    .toggle_section{
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i{
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown2 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 10000000px;
        overflow: visible;
        animation: smoothSlideup2 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    @keyframes smoothSlideup2 {
        from {max-height: 0;}
        to {max-height: 10000000px;}
    }
    @keyframes smoothSlidedown2 {
        from {max-height: 10000000px;}
        to {max-height: 0;}
    }
    .global_setting .tabs_content .section_content .toggle_content .setting_wpr{
        height: auto;
    }
    .global_setting .tabs_content .section_content:last-of-type:after{
        border: 0;
    }
    .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 10px;
    }
    .btn_list li{
        background: #fff;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 3px 10px;
        cursor: pointer;
    }
    .empty_items {
        padding: 30px 15px;
        border: 1px solid #f5f5f5;
        background: #fafafa;
        border-radius: 6px;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        gap: 30px;
        font-size: 13px;
        line-height: 18px;
        color: #999;
        font-weight: 400;
        text-align: center;
    }
    /* .add_tabs {
        color: #5a5a5a;
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding: 8px 20px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
        margin: 5px auto;
        cursor: pointer;
    }
    .add_tabs i{
        font-size: 11px;
        margin-right: 5px;
    } */
    .tab_item{
        margin: 20px 0 15px;
        border-width: 1px;
        border-style: solid;
        border-color: #e9e9e9;
        border-image: initial;
        border-radius: 6px;
    }
    .tab_item .top_title{
        padding: 20px 30px;
        background: #fbfbfb;
        border-radius: 6px;
    }
    .tab_item .tab_details{
        border-top: 1px solid #e9e9e9;
        padding : 10px 30px 30px 30px;
    }
    .tabs_content {
        padding-top: 0;
    }

    .tabs_btn li.add_btn {
        margin-top: auto;
    }

    .playbook-step {
        display: flex;
        flex-direction: column;
        height: inherit;
    }

    .tabs_btn li.add_btn button {
        width: 100%;
        padding: 10px 20px;
        background: #2f7eed;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        font-size: 13px;
        line-height: 16px;
        /* text-transform: uppercase; */
        /* letter-spacing: 1px; */
    }

    .tabs_btn li.add_btn button i {
        font-size: 11px !important;
        margin-right: 5px;
        color: #fff !important;
    }
    .tabs_btn li .handle-cat-drag{
        cursor: move;
        padding-right: 10px;
        font-size: 10px;
    }
    .tabs_btn li.active .handle-cat-drag{
        color: #121525;
    }

    .topTabs {
        position: sticky;
        top: 0;
        background: #FAFAFB;
        padding-top: 25px;
        z-index: 4;
    }

    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
        z-index: 4;
    }

    .slide_btn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #cfcfcf;
        background: #eaeaea;
        cursor: pointer;
        position: absolute;
        right: 100%;
        margin-right: 30px;
        border-radius: 50%;
        font-size: 15px;
        color: #5a5a5a;
    }

    .preview_content {
        position: relative;
        width: 40%;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 5;
        margin-right: -40%;
    }

    .preview_content .content_area {
        height: calc(100vh - 235px);
    }

    .preview_content.show {
        margin-right: 0;
    }

    .delete {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 0;
        top: 2px;
        background: #eb1414;
        color: #fff;
        border-radius: 50%;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        cursor: pointer;
    }

    .tab_sec li{
        line-height: 25px;
    }

    .tab_sec li.active .delete {
        opacity: 1;
    }

    .tabs_action {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .tabs_action li {
        padding: 0 5px;
        border-right: 1px solid #d4d4d4;
    }
    .tabs_action li:last-child {
        border-right: 0;
        padding-right: 0;
    }
    .tabs_action .add_btn{
        font-size: 13px;
        line-height: 16px;
        margin: 0;
    }
    .tabs_action .add_btn i{
        font-size: 9px !important;
    }

    .tab_content {
        background: #f9f9f9;
        /* border: 1px solid #e9e9e9; */
        /* border-top: 0; */
        padding: 10px 20px 20px;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    /* Preview area */
    /* .dashboard_content {
        margin: 0 -10px;
    } */

    .dashboard_content .left_side{
        padding: 15px;
        width: 100%;
        margin-top: 15px;
    }
    .dashboard_content .right_side {
        padding: 15px;
        width: 100%;
        margin-bottom: 10px;
        position: relative;
    }
    .dashboard_content .right_side:before {
        content: "";
        position: absolute;
        top: 0;
        left: 20px;
        right: 20px;
        border-top: 1px solid #e9e9e9;
    }

    .dashboard_content h3 {
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
    }

    .border-item {
        padding: 20px 20px 15px 20px;
        border: 1px solid #eaeaea;
        border-radius: 8px;
        background: #fff;
    }

    .dashboard_content .dotted_item h3 {
        align-items: center;
    }

    .dashboard_content .para {
        font-size: 11px;
        line-height: 15px;
        color: #585858;
        font-weight: 400;
    }
    .dashboard_content .para{
        line-height: 1.3;
    }
    :deep(.dashboard_content .para h1), :deep(.dashboard_content .para h2), :deep(.dashboard_content .para h3), :deep(.dashboard_content .para h4), :deep(.dashboard_content .para h5), :deep(.dashboard_content .para h6){
        margin-bottom: 15px;
    }
    :deep(.dashboard_content .para img){
        margin-bottom: 10px;
    }

    .step_banner,
    :deep(.video_wpr) {
        margin-bottom: 15px;
    }
    .player_wpr{
        padding-bottom: 56.25%;
        position: relative;
    }
    .player_wpr :deep(iframe){
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%
    }

    .step_banner img {
        display: block;
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    :deep(.video_wpr img) {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    :deep(.video_wpr iframe) {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .scr_tab{
        overflow-x: scroll;
        /* padding-top: 5px; */
        padding-bottom: 2px;
        margin: 0;
        border: 0;
    }
    .scr_tab li{
        min-width: max-content;
        padding-right: 25px;
        /* padding: 8px 25px 8px 0; */
        text-align: center;
        font-size: 13px;
        line-height: 18px;
    }
    .section_item .tabs {
        display: flex;
        list-style-type: none;
        align-items: center;
        border-bottom: 1px solid #dfdfdf;
        overflow-x: scroll;
        width: 100%;
        margin: 30px 0 15px 0;
    }

    .section_item .tabs::-webkit-scrollbar, .section_content .scr_tab::-webkit-scrollbar {
        height: 8px;
    }

    .section_item .tabs::-webkit-scrollbar-thumb, .section_content .scr_tab::-webkit-scrollbar-thumb {
        background-color: #e4e4e4;
        border-radius: 4px;
    }

    .section_item .tabs li {
        font-size: 11px;
        line-height: 14px;
        color: #5a5a5a;
        font-weight: 400;
        padding: 5px 10px;
        position: relative;
        overflow: hidden;
        border-radius: 3px 3px 0 0;
        /* margin-right: 20px; */
        cursor: pointer;
        min-width: max-content;
        text-align: center;
    }

    /* .section_item .tabs li:after {
        content: '';
        position: absolute;
        left: -100%;
        bottom: 0;
        width: 100%;
        border-bottom: 1px solid var(--var-color);
        transition: all 0.3s ease-in-out;
    } */

    .section_item .tabs li.active {
        font-weight: 500;
        color: #121525;
    }

    .section_item .tabs li.active:after {
        left: 0;
    }

    .description {
        display: flex;
        margin: 0 -7px 10px -7px;
    }

    .description .desc_info {
        padding: 5px 7px;
        width: 60%;
    }

    .description .desc_img {
        width: 40%;
        padding: 0 7px;
        height: auto;
        position: relative;
        border-radius: 5px;
        margin: 5px 7px;
        border-radius: 5px;
        overflow: hidden;
    }

    .description .desc_img img {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .preview_content .status {
        height: 5px;
        border-radius: 3px;
        background: #ddd;
        position: relative;
        max-width: 100%;
    }

    .preview_content .status span {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 3px;
        background: var(--var-color);
    }
    .step_card {
        padding: 0 15px 25px 15px;
    }

    .step_card .items {
        display: flex;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        overflow: hidden;
    }
    .step_card .thumb {
        flex: 0 0 106px;
        position: relative;
    }

    .step_card .thumb>img {
        position: absolute;
        left: 0;
        top: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .step_card .step_info {
        padding: 6px 10px;
        flex: 1;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 61px;
    }

    .step_card .step_info h5 {
        font-size: 11px;
        line-height: 13px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 3px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .step_card .step_info p {
        font-size: 9px;
        line-height: 11px;
        font-weight: 400;
        color: #5a5a5a;
        height: 22px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .product_card {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        overflow: hidden;
        padding: 0;
        line-height: 0;
        background: #fff;
    }

    .product_card h3 {
        padding: 20px 30px;
        margin: 0;
    }

    .product_card img {
        width: 100%;
        /* height: 130px; */
        object-fit: cover;
    }

    .coach_card {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        padding: 10px 15px;
        margin-bottom: 35px;
    }

    .coach_card .user_box {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }

    .coach_card .user_box img {
        width: 40px;
        height: 40px;
        margin-right: 15px;
    }

    .coach_card .user_box .user_info h3 {
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        color: #121525;
        margin: 0;
    }

    .coach_card .user_box .user_info h5 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 400;
        margin: 5px 0 0 0;
    }

    :deep(.coach_card p *) {
        font-size: 11px !important;
        line-height: 15px;
        font-weight: 300;
        color: #717171;
        margin: 15px 0 5px 0;
    }
    .dashboard_content .action_sec h3{
        font-weight: 500;
    }
    .button_list li {
        margin-top: 20px;
    }

    .button_list li button {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        color: #121525 !important;
        background: transparent !important;
        padding: 0 !important;
        /* padding: 12px 20px; */
        /* border-radius: 4px; */
        display: flex;
        align-items: center;
        width: 100%;
    }
    .button_list li button i{
        font-size: 9px;
        padding-right: 10px;
    }
    .downloads{
        margin-top: 30px;
        padding: 0 0 10px 0;
        border: 0;
        background: transparent;
    }
    .download_list {
        padding-top: 10px;
        background: transparent;
        border: 0;
        padding: 0;
    }

    .download_list li {
        border: 1px solid #eaeaea;
        box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        border-radius: 5px;
        overflow: hidden;
        margin-bottom: 10px;
        min-height: 40px;
    }

    .download_list li img {
        width: 40px;
        height: auto;
    }

    .download_list li h5 {
        padding: 0 12px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        word-break: break-all;
        justify-content: flex-start;
    }
    .download_list li h5 i{
        font-size: 14px;
        margin-right: 8px;
    }

    .download_list li span {
        margin-left: auto;
        font-size: 12px;
        width: 40px;
        height: auto;
        border-left: 1px solid #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .faq_list {
        list-style-type: none;
    }

    .faq_list li {
        border: 1px solid #eaeaea;
        padding: 15px;
        border-radius: 6px;
        margin-bottom: 15px;
        background: #fff;
    }

    .faq_list li h5 {
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .faq_list li h5 i {
        transform: rotate(0deg);
        transition: all 0.3s ease-in-out;
    }
    :deep(.faq_list li p){
        font-size: 11px;
        line-height: 16px;
    }

    .faq_list li p {
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        padding-top: 10px;
        display: none;
    }

    .faq_list li.show p {
        display: block;
    }

    .faq_list li.show h5 i {
        transform: rotate(-180deg);
    }

    .card_body {
        width: 100%;
        position: relative;
        overflow: hidden;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .card_body .banner_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        left: 0;
        top: 0;
        z-index: 1;
    }

    .card_body picture{
        display: block;
        line-height: 0;
    }

    .card_body .banner_content{
        position: absolute;
        width: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        z-index: 2;
    }

    .card_body h1 {
        font-size: 30px;
        line-height: 45px;
        font-weight: 600;
        color: #fff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.68);
        /* text-transform: uppercase; */
        position: relative;
        z-index: 3;
    }

    .card_body h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
        color: #fff;
        position: relative;
        z-index: 3;
    }

    .card_body .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    :deep(.color_container .group_item .color-picker) {
        margin-top: 5px;
    }

    :deep(.email_comp .form_grp) {
        margin: 0;
        width: 100%;
    }

    :deep(.email_comp .form_grp .group_item) {
        margin: 0;
        width: 100%;
    }

    :deep(.email_comp .dropdown ul li) {
        width: 100%;
    }

    :deep(.email_comp .form_grp .group_item .input_label) {
        display: flex;
        margin-bottom: 3px;
        justify-content: space-between;
    }

    :deep(.sms-component .group_item),
    :deep(.sms-component .form_grp) {
        margin: 0;
        width: 100%;
    }

    :deep(.sms-component .group_item .input_label) {
        display: flex;
        margin-bottom: 3px;
    }

    .dropzone {
        border: 1.2px dashed #95BFFF;
        background: #FAFCFD;
        min-height: 150px;
    }

    :deep(.dz-message) {
        vertical-align: middle;
        margin: auto;
        display: grid;
        height: 100%;
        min-height: 150px;
    }

    :deep(.dz-message div) {
        margin: auto;
    }
    :deep(.dashboard_content .para h3){
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 10px;
    }
    :deep(.dashboard_content .para p){
        font-size: 16px;
        line-height: 32px;
        color: #0E101A;
        font-weight: 400;
        min-height: 32px;
    }
    :deep(.cell .dashboard_content .para p){
        font-size: 16px;
        line-height: 32px;
        min-height: 32px;
    }
    :deep(.dashboard_content .para ol), :deep(.dashboard_content .para ul){
        padding-left: 18px;
        margin-bottom: 15px;
    }
    :deep(.dashboard_content .para ol li){
        list-style-type: decimal;
        padding: 3px 0;
        font-size: 13px;
        line-height: 16px;
    }
    :deep(.dashboard_content .para ul li){
        list-style-type: disc;
        padding: 3px 0;
        font-size: 13px;
        line-height: 16px;
    }
    /* :deep(.dashboard_content .para p:last-child){
        margin: 0;
    } */
    :deep(.dashboard_content .para figure img){
        width: 100%;
    }
    
    .color_container .download_list li {
        min-height: 44px;
    }
    .color_container .download_list li h5 i{
        font-size: 16px;
        margin-right: 10px;
    }
    .preview_content .cell{
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
    }
    .preview_content .cell:after, .preview_content .cell:before{
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_content .cell:before{
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }
    .preview_content .cell:after{
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }
    .preview_content .cell .content_area{
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #f5f5f5;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .steps_status{
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #fff;
        background: #2f7eed;
        padding: 15px 20px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .tab_status{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #fff;
        padding: 5px 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tab_status i{
        padding-left: 8px;
    }
    /* .cell .dashboard_content .section_item{
        padding: 10px 0;
    } */

    .faq-acc-header {
        padding: 15px;
        margin: -15px -15px 0px -15px;
    }

    ul.faq_list li:first-child .faq-acc-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
    }

    ul.faq_list li.small-preview {
        padding-bottom: 0;
    }

    ul.faq_list li.small-preview .faq-acc-header {
        border-radius: 6px;
    }

    .global_setting .tabs_btn li:not(:last-child) {
        border-bottom: 1px solid #f3f3f3;
        margin: 0;
    }

    .preview_content .close_btn {
        position: absolute;
        left: -35px;
        top: 2px;
    }

    .w-70 {
        width: 70px !important;
    }

    .download-action span {
        border-left: 0px !important;
        height: 100% !important;
    }

    .download-action span +  span {
        border-left: 1px solid #eaeaea !important;
    }

    .download_list h5 {
        width: 100%;
    }

    .download-editor {
        width: 100%;
        height: 100%;
        background: transparent;
    }

    .download-error {
        margin-bottom: 10px;
        margin-top: -10px;
        text-align: left;
        font-size: 14px;
    }

    li.has-error {
        border-color: #eb1414;
    }

    .step_card {
        position: relative;
    }

    .preview_content .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }
    .preview_content .dashboard_footer ul li{
        padding: 2px;
    }
    .preview_content .dashboard_footer ul li a {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }
    .preview_content .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }
    .preview_content .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }
    .preview_content .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }
    .client_card {
        width: 100%;
        border-radius: 0 0 5px 5px;
    }

    .client_card .card_header {
        padding: 15px 20px;
        border-radius: 0;
    }

    .client_card .card_header h4 {
        font-size: 14px;
        line-height: 20px;
    }
    .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 14px;
    }
    .logo_img{
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }
    .step_listing {
        border: 1px solid #eaeaea;
        border-radius: 8px;
        background: #fff;
        padding: 25px 0 15px 0;
        margin-bottom: 35px;
    }
    .step_listing h3{
        padding: 0 15px;
    }
    .progress_sec, .product_card, .coach_card, .action_sec{
        margin-bottom: 35px;
    }
    .right_side .step_listing, .coach_card, .right_side .product_card, .faq{
        display: none;
    }

    .area_body_v2.cell .step_banner img,
    .area_body_v2.cell .video_wpr img {
        height: auto;
    }

    :deep(.tab-content figure div a) {
        display: block;
        font-size: 20px;
        padding-left: 76px !important;
        padding-right: 76px !important;
    }

    .global_setting .msg_preview {
       padding: 20px;
       background: #fff;
       border: 1px solid #eaeaea;
       margin: 15px;
       position: relative;
       border-radius: 10px;
    }

    .global_setting .sms_preview {
       padding: 15px 20px;
       background: #fff;
       border: 1px solid #eaeaea;
       margin: 15px;
       position: relative;
       border-radius: 10px;
       font-size: 12px;
       line-height: 17px;
       color: #5a5a5a;
    }

    .global_setting .msg_preview:after,
    .global_setting .sms_preview:after {
       position: absolute;
       content: '';
       background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
       background-repeat: no-repeat;
       text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
       width: 20px;
       height: 13px;
       left: calc(50% - 10px);
       top: 100%;
    }

    .global_setting .sms_preview:after {
       right: 30px;
       left: auto;
    }

    .global_setting .msg_preview p {
       font-size: 11px;
       line-height: 15px;
       color: #5a5a5a;
       font-weight: 400;
       margin-bottom: 10px;
    }

    .global_setting .msg_preview p span {
       font-size: 14px;
       line-height: 19px;
       display: block;
       padding-bottom: 3px;
       font-weight: 500;
       color: #121525;
    }

    .cell .msg_preview h2 {
       font-size: 13px;
       line-height: 18px;
       margin-bottom: 15px;
       font-weight: 500;
       color: #121525;
    }

    .cell .msgFooter p {
       font-size: 11px;
       line-height: 17px;
    }

    .cell .msgFooter p a {
       text-decoration: none;
    }

    .email-preview,
    :deep(.redactor-styles.redactor-in.email-preview p) {
       font-size: 11px !important;
       line-height: 15px !important;
       color: #5a5a5a;
       font-weight: 400;
       margin-bottom: 10px;
    }

    .cell .email-preview {
       overflow: hidden;
    }
    .cell .mobile_menu{
        display: flex;
        padding: 30px 15px 0 15px;
        /* margin-bottom: -15px; */
    }
    .cell .mobile_menu li {
        font-size: 13px;
        line-height: 16px;
    }
    .slide_box_container{
        height: 610px;
        position: absolute;
        top: 60px;
        bottom: 80px;
        left: 15px;
        right: 15px;
        overflow: hidden;
        z-index: 2;
    }
    .slide_box {
        padding: 20px;
        border-radius: 20px 20px 0 0;
        background: #fff;
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: -100%;
        max-height: 430px;
        transition: all .3s ease-in-out;
        box-shadow: 0 -2px 30px rgba(0,0,0,.15);
        text-align: left;
        z-index: 3;
        overflow-y: auto;
    }
    .slide_box::-webkit-scrollbar{
        width: 4px;
    }
    .slide_box::-webkit-scrollbar-thumb{
        background: #dbdbdb;
        border-radius: 2px;
    }
    .slide_box.active{
        bottom: 1px;
    }
    .preview_content .slide_box .close_btn{
        width: 20px;
        height: 20px;
        left: auto;
        right: 15px;
        top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        background: rgba(50,55,59,.6);
        color: #fff;
    }
    :deep(.slide_box .instructor p){
        font-size: 15px;
        line-height: 27px;
        font-weight: 300;
        color: #0E101A;
    }
    .slide_box .step_listing h3 {
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        padding: 0;
    }
    .slide_box .step_listing ul {
        list-style-type: none;
        max-height: 352px;
        overflow-y: scroll;
        margin-top: 20px;
    }
    .slide_box .step_listing ul::-webkit-scrollbar{
        display: none;
    }
    .slide_box .step_listing .step_card{
        padding: 0 0 25px 0;
    }
    .slide_box .product_card{
        border: 0;
        border-radius: 0;
        margin: 0;
    }
    .slide_box .product_card h3{
        padding: 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 15px;
    }
    .slide_box .progress_sec{
        margin-bottom: 30px;
    }
    .slide_box .progress_sec h3{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    .step_loader_area {
        height: 100vh;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }
    :deep(figure.redactor-component){
        position: static;
    }
    .btn_list li.tag_setup{
        color: #5a5a5a;
        font-size: 18px;
        position: relative;
        background: transparent;
        padding: 0 5px 0 10px;
    }
    .tag_setup .dropdown_wpr{
        width: 300px;
        padding: 20px 15px 15px 15px;
        left: auto;
        right: -10px;
    }
    .tag_setup .field_wpr input[type=text]{
        height: 35px;
        padding: 0 10px;
    }
    .tag_setup .field_wpr.has_suffix{
        padding-right: 50px;
    }
    .tag_setup .field_wpr.has_suffix .suffix {
        background: #f5f5f5;
        width: 50px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        border-radius: 0 5px 5px 0;
    }
    :deep(.redactor-styles){
        height: 400px;
        max-height: 800px !important;
        resize:vertical;
        overflow-y: scroll;
    }
    .section_title{
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .section_title h4{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        margin-right: auto;
    }
    .section_title h4 label{
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
        color: #6a6a6a;
        display: block;
        padding: 3px 0;
    }
    .step_drips {
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        margin-top: 20px;
        background: #fff;
    }
    .step_drips .section_title{
        padding: 20px 30px;
        border-radius: 6px;
    }
    .step_drips.tabs .section_title{
        background: #fbfbfb;
    }
    .drip_details{
        border-top: 1px solid #fff;
        padding: 0 30px;
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
    }
    .step_drips.show > .drip_details{
        border-top: 1px solid #e9e9e9;
        padding: 10px 30px;
        max-height: max-content;
    }
    .step_drips.tabs.show > .drip_details{
        padding-bottom: 20px;
    }
    .toggle_details{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
        margin: 0 15px;
        cursor: pointer;
    }
    .global_setting .color_container.show .toggle_details{
        transform: rotate(-180deg);
    }

    .dashboard_content .content_details{
        background: #fff;
        /* border: 1px solid #eaeaea; */
        border-radius: 8px;
        padding: 20px;
    }
    .dashboard_content .content_details :deep(p.para figure.embed-video),
    :deep(.redactor-styles.redactor-in figure.embed-video) {
        padding-bottom: 56.25%;
        position: relative;
        margin-bottom: 15px;
    }

    .dashboard_content .content_details :deep(p.para figure.embed-video iframe),
    :deep(.redactor-styles.redactor-in figure.embed-video iframe),
    .dashboard_content .content_details :deep(p.para figure.embed-video img) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
    .progress_status{
        margin-bottom: 20px;
    }
    .progress_status .status{
        height: 8px;
        border-radius: 4px;
        background: #ddd;
        position: relative;
        max-width: 100%;
        overflow: hidden;
    }
    .progress_status .status span{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        border-radius: 0;
        background: var(--var-progress-color);
        z-index: 1;
    }
    .progress_status h5{
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        color: #121525;
        text-align: center;
        padding-top: 8px;
    }
    .global_setting .tabs_btn li.close_btn {
        padding: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #dfdfdf;
        display: none;
        align-items: center;
        justify-content: center;
        color: #2f7eed;
        position: absolute;
        left: 10px;
        top: 10px;
        z-index: 9;
        cursor: pointer;
        transition: all 0.5s ease-in-out;
    }
    .global_setting .tabs_btn li.close_btn img {
        max-width: 14px;
        height: auto;
        transform: scaleX(-1);
    }
    @media(max-width: 1199px){
        .global_setting .tabs_content .btn_list{
            margin-top: -12px;
            padding: 0 20px 0 0;
        }
        .global_setting .tabs_content .section_content:after{
            left: 10px;
            right: 10px;
        }
        .global_setting .tabs_btn li.close_btn{
            display: flex;
        }
    }
    @media(max-width: 499px){
        .global_setting .tabs_content .section_content:after{
            left: 0;
            right: 0;
        }
        .global_setting .section_header h2{
            font-size: 18px;
            line-height: 25px;
        }
        .toggle_section{
            margin-left: 10px;
        }
    }
</style>
